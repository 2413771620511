import React from 'react';
import cx from 'classnames';
import { Row, Col } from 'antd';
import { useTranslate } from 'react-translate';
import { withRouter } from 'react-router-dom';
import { Button } from '../../common/components'
// import styles from './Home.module.scss';
import Slider from './Slider';

import styles from '../Campaigns/Campaigns.module.scss';

import VehicleImage from '../../assets/c5_new.png';

const Campaigns = ({ history }) => {
  const homeT = useTranslate('home');
  const commonT = useTranslate('common');

  return (
    // <Row gutter={16} className={styles.campaignWrapper}>
    //   <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 12 }} className={styles.campaign}>
    //     <h2>{homeT('aracFilomuz')}</h2>
    //     <img
    //       src={VehicleImage}
    //       alt="kampanya"
    //     />
    //     <div className={styles.campaignContent}>
    //       <p>{homeT('bannerLeftTitle')}</p>
    //       <Button
    //         type="primary"
    //         round
    //       >
    //         {commonT('fiyatListesi')}
    //       </Button>
    //     </div>
    //   </Col>
    //   <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 12 }} className={styles.carousel}>
    //     <h2>{commonT('kampanyalar')}</h2>
    //     <Slider />
    //   </Col>
    // </Row>
    <div>
      <div className={styles.campaigns}>
        <div className={cx(styles.column, styles.homeColumn)}>
          <h2>{homeT('aracFilomuz')}</h2>
          <div className={styles.campaign, styles.campaignRed}>
            <div className={styles.campaignContent, styles.campaignContentRed} style={{ backgroundImage: `url(${VehicleImage})`, backgroundSize: '300px 220px' }}>
              <p>{homeT('bannerLeftTitle')}</p>
              <Button
                type="primary"
                round
                onClick={() => history.push('/fleet')}
              >
                {commonT('fiyatListesi')}
              </Button>
            </div>
          </div>
        </div>
        <div className={cx(styles.column, styles.homeColumn)}>
          <h2>{commonT('kampanyalar')}</h2>
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Campaigns);

import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import { Popover } from 'antd';

import { CheckBox } from '../';
import { getName, getKeyByLang } from '../../../utils'

import styles from './Extras.module.scss';

import InfoIcon from '../../../assets/info.svg';
import BlueTLIcon from '../../../assets/blue-tl.svg';

const Extras = ({ extras, setSelectedExtras, selectedExtras }) => {
  const t = useTranslate('vehicle');

  const [extraDriverCount, setExtraDriverCount] = useState(0);
  const [extraChildSeatCount, setExtraChildSeatCount] = useState(0);
  const [extrasState, setExtrasState] = useState({
    babySeatExtra: false,
    navigationExtra: false,
    fullInsuranceExtra: false,
    semiInsuranceExtra: false,
    driverExtra: false,
  });

  const handleExtra = (e, extra) => {
    setExtrasState({
      ...extrasState,
      [e.target.name]: e.target.checked,
    });

    if(e.target.checked) {
      setSelectedExtras([
        ...selectedExtras, {
          ...extra,
          quantity: 1,
          // name: getName(extra)
        }
      ])
    } else {
      setSelectedExtras([
        ...selectedExtras.filter(ex => ex.id !== extra.id)
      ])
    }
  }

  const babySeatExtra = extras.find(dt => dt.type === 'baby-seat') || {};
  const navigationExtra = extras.find(dt => dt.type === 'navigation') || {};
  const fullInsuranceExtra = extras.find(dt => dt.type === 'full-insurance') || {};
  const semiInsuranceExtra = extras.find(dt => dt.type === 'semi-insurance') || {};
  const driverExtra = extras.find(dt => dt.type === 'extra-driver') || {};

  const changeCounter = (type, count) => {
    if(count < 0) {
      return null;
    }

    if(type === 'extraChildSeatCount' && count > 2) return null;
    if(type === 'extraDriverCount' && count > 3) return null;

    if(type === 'extraDriverCount') {
      setExtraDriverCount(count);
      if(count !== 0) {
        const filtered = selectedExtras.filter(extra => extra.id !== driverExtra.id);
        setSelectedExtras([
          ...filtered, { ...driverExtra, quantity: count,
            // name: getName(driverExtra)
          }
        ])
      } else {
        setSelectedExtras([...selectedExtras.filter(extra => extra.id !== driverExtra.id)])
      }
    } else {
      setExtraChildSeatCount(count);
      if(count !== 0) {
        const filtered = selectedExtras.filter(extra => extra.id !== babySeatExtra.id);
        setSelectedExtras([
          ...filtered, { ...babySeatExtra, quantity: count,
            // name: getName(babySeatExtra)
          }
        ])
      } else {
        setSelectedExtras([...selectedExtras.filter(extra => extra.id !== babySeatExtra.id)])
      }
    }
  }


  const fullInsuranceExtraToolTipContent = (
    <div>
      <p>{getKeyByLang(fullInsuranceExtra, 'description')}</p>
    </div>
  );
  const semiInsuranceExtraToolTipContent = (
    <div>
      <p>{getKeyByLang(semiInsuranceExtra, 'description')}</p>
    </div>
  );

  const rentTermsTooltip = (
    <div className={styles.rentTermsTooltip}>
      <h3>{t('kiralamaSartlari')}</h3>
      <ul>
        <li>{t('depozitoBilgisi')}: <strong>500 </strong>₺</li>
        <li>{t('minimumSurucuYasi')}: <strong>21</strong></li>
        <li>{t('minimumEhliyetYasi')}: <strong>2</strong></li>
      </ul>
      <p>
        {t('rentTermsTooltipAciklama')}
      </p>
    </div>
  );

  return (
    <div className={styles.root}>
      <h2>{t('aracEkstraSecimi')}</h2>
      <div className={styles.wrapper}>
        <ul>
          <li>
            <CheckBox
              checked={extrasState.fullInsuranceExtra}
              rounded
              name="fullInsuranceExtra"
              onChange={(e) => {
                if(e.target.checked) {
                  setSelectedExtras([
                    ...selectedExtras.filter(ex => ex.id !== semiInsuranceExtra.id), {
                      ...fullInsuranceExtra,
                      quantity: 1,
                      // name: getName(fullInsuranceExtra)
                    }
                  ]);
                  setExtrasState({
                    ...extrasState,
                    [e.target.name]: e.target.checked,
                    semiInsuranceExtra: false,
                  });
                } else {
                  setSelectedExtras([
                    ...selectedExtras.filter(ex => ex.id !== fullInsuranceExtra.id)
                  ])
                  setExtrasState({
                    ...extrasState,
                    [e.target.name]: e.target.checked,
                  });
                }
              }}
            >
              {getName(fullInsuranceExtra)}
            </CheckBox>
            <Popover content={fullInsuranceExtraToolTipContent}>
              <span><img src={InfoIcon} alt="info" /></span>
            </Popover>
            <strong>{fullInsuranceExtra.price}<img src={BlueTLIcon} alt="TL" /> <span> / {t('gun')}</span> </strong>
          </li>
          <li>
            <CheckBox
              checked={extrasState.semiInsuranceExtra}
              rounded
              name="semiInsuranceExtra"
              onChange={(e) => {
                if(e.target.checked) {
                  setSelectedExtras([
                    ...selectedExtras.filter(ex => ex.id !== fullInsuranceExtra.id), {
                      ...semiInsuranceExtra,
                      quantity: 1,
                      // name: getName(semiInsuranceExtra)
                    }
                  ]);
                  setExtrasState({
                    ...extrasState,
                    [e.target.name]: e.target.checked,
                    fullInsuranceExtra: false,
                  });
                } else {
                  setSelectedExtras([
                    ...selectedExtras.filter(ex => ex.id !== semiInsuranceExtra.id)
                  ])
                  setExtrasState({
                    ...extrasState,
                    [e.target.name]: e.target.checked,
                  });
                }
              }}
            >
              {getName(semiInsuranceExtra)}
            </CheckBox>
            <Popover content={semiInsuranceExtraToolTipContent}>
              <span><img src={InfoIcon} alt="info" /></span>
            </Popover>
            <strong>{semiInsuranceExtra.price}<img src={BlueTLIcon} alt="TL" /> <span> / {t('gun')}</span> </strong>
          </li>
          <li className={styles.navigation}>
            <CheckBox
              checked={extrasState.navigationExtra}
              rounded
              name="navigationExtra"
              onChange={(e) => handleExtra(e, navigationExtra)}
            >
              {getName(navigationExtra)}
            </CheckBox>
            <strong>{navigationExtra.price}<img src={BlueTLIcon} alt="TL" /><span> / {t('gun')}</span></strong>
          </li>
        </ul>
        <ul>
          <li className={styles.count}>
            <div className={styles.countButtons}>
              <button
                onClick={() => changeCounter('extraDriverCount', extraDriverCount - 1)}
              >
                -
              </button>
              <span>{extraDriverCount}</span>
              <button
                onClick={() => changeCounter('extraDriverCount', extraDriverCount + 1)}
              >
                +
              </button>
            </div>
            <span>{getName(driverExtra)}</span>
            <strong>{driverExtra.price}<img src={BlueTLIcon} alt="TL" /> <span> / {t('gun')}</span></strong>
          </li>
          <li className={styles.count}>
            <div className={styles.countButtons}>
              <button
                onClick={() => changeCounter('extraChildSeatCount', extraChildSeatCount - 1)}
              >
                -
              </button>
              <span>{extraChildSeatCount}</span>
              <button
                onClick={() => changeCounter('extraChildSeatCount', extraChildSeatCount + 1)}
              >
                +
              </button>
            </div>
            <span>{getName(babySeatExtra)}</span>
            <strong>{babySeatExtra.price}<img src={BlueTLIcon} alt="TL" /> <span> / {t('gun')}</span></strong>
          </li>
          <li className={styles.vehicleRent}>
            <Popover content={rentTermsTooltip} placement="left">
              <span><img src={InfoIcon} alt="info" /> {t('kiralamaSartlari')}</span>
            </Popover>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Extras;

import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { SearchForm, Helmet } from '../../common/components'
import styles from './Home.module.scss';
import DownloadBanner from './DownloadBanner';
import Campaigns from './Campaigns';
import { getSearchParamsValues } from '../../utils';
import AppUse from '../App/AppContext';

const Home = (props) => {
  const { regions } = AppUse();

  return (
    <div className={styles.root}>
      <Helmet title="Ana Sayfa" />
      <div className={styles.wrapper}>
        {regions.data && !!regions.data.length && (
          <SearchForm
            values={props.location.search && getSearchParamsValues(queryString.parse(props.location.search))}
            onChange={(values) => {
              const stringified = queryString.stringify(values);
              props.history.push(`/search?${stringified}`);
            }}
          />
        )}
        <div className={styles.homeContent}>
          <Campaigns />
          <DownloadBanner />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
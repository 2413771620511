const config = {
  production: {
    baseUrl: "https://api.frent.com.tr/",
    clientId: "5dac3f2744a39",
    clientSecret: "0682d4256668d0cd13f9caad54954d1a"
  },
  staging: {
    baseUrl: "https://apibeta.frent.com.tr/",
    clientId: "5d56c46bb89a6",
    clientSecret: "b458578632e1850ba1cdad0841517fe2"
  },
  development: {
    baseUrl: "https://apibeta.frent.com.tr/",
    clientId: "5d56c46bb89a6",
    clientSecret: "b458578632e1850ba1cdad0841517fe2"
  }
}[process.env.REACT_APP_STAGE || "development"];

export default config;

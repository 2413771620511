import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "react-translate";
import { Row, Col, BackTop } from "antd";
import MediaQuery from "react-responsive";

import styles from "./Footer.module.scss";

import Logo from "../../../../assets/footer-logo.svg";
import IosDownloadIcon from "../../../../assets/ios-download.svg";
import GoogleDownloadIcon from "../../../../assets/google-download.svg";
import InstagramIcon from "../../../../assets/instagram.svg";
import TwitterIcon from "../../../../assets/twitter.svg";
import FacebookIcon from "../../../../assets/facebook.svg";
import ScrollUpIcon from "../../../../assets/scroll-up.svg";
import { InfoModal } from "../../../../common/components";

const Footer = () => {
  const t = useTranslate("common");

  const [rentInfoModalVisible, setRentInfoModalVisible] = useState(false);
  const [rentTermsModalVisible, setRentTermsModalVisible] = useState(false);
  const [aboutModalVisible, setAboutModalVisible] = useState(false);
  const [termsConditionsVisible, setTermsConditionsVisible] = useState(false);

  return (
    <footer className={styles.root}>
      <footer className={styles.wrapper}>
        <MediaQuery maxWidth={768}>
          <BackTop visibilityHeight={0}>
            <div className="ant-back-top-inner">
              <img src={ScrollUpIcon} alt="" />
            </div>
          </BackTop>
        </MediaQuery>
        <Row gutter={{ md: 16, xl: 16, xxl: 16 }}>
          <Col md={6} xl={6} sm={12} xs={12} className={styles.about}>
            <img src={Logo} alt="logo" />
            <a href="tel:+90 212 299 60 61">+90 212 299 60 61 </a>
            <a href="tel:+90 212 299 60 62">+90 212 299 60 62 </a>
            <a href="tel:+90 530 305 52 82">+90 530 305 52 82 </a>
            <p>Tarabya Bayırı Cad. Bostan Sokak No:1 34457</p>
            <p>Sarıyer/İstanbul</p>
          </Col>
          <Col md={4} xl={4} sm={12} xs={12} className={styles.list}>
            <h3>{t("fRent")}</h3>
            <ul>
              <li>
                <Link to="/fleet">{t("filo")}</Link>
              </li>
              <li>
                {/* <Link to="/faq">{t('sss')}</Link> */}
                <a onClick={() => setAboutModalVisible(true)}>
                  {t("hakkimizda")}
                </a>
              </li>
              <li>
                <Link to="/campaigns">{t("kampanyalar")}</Link>
              </li>
              <li>
                <Link to="/corporate-rent">{t("kurumsalKiralama")}</Link>
              </li>
            </ul>
          </Col>
          <Col md={4} xl={4} sm={12} xs={12} className={styles.list}>
            <h3>{t("yardimVeBilgi")}</h3>
            <ul>
              <li>
                <a onClick={() => setRentInfoModalVisible(true)}>
                  {t("aracKiralamaRehberi")}
                </a>
              </li>
              <li>
                <a onClick={() => setTermsConditionsVisible(true)}>
                  {t("GizlilikPolitikamiz")}
                </a>
              </li>
              <li>
                {/* <Link to="/faq">{t('sss')}</Link> */}
                <a onClick={() => setRentTermsModalVisible(true)}>
                  {t("kiralamaKosullari")}
                </a>
              </li>
              <li>
                <Link to="/contact">{t("iletisim")}</Link>
              </li>
            </ul>
          </Col>
          <Col md={4} xl={4} sm={12} xs={12} className={styles.list}>
            <h3>{t("rezervasyon")}</h3>
            <ul>
              <li>
                <Link to="/show-reservation">
                  {t("rezervasyonGoruntuleme")}
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={6} xl={6} sm={24} xs={24} className={styles.social}>
            <h3>{t("takipEdin")}</h3>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/frentacar2019/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FacebookIcon} alt="facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/frentacar2019"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TwitterIcon} alt="twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https:///www.instagram.com/frentacar2019"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={InstagramIcon} alt="instagram" />
                </a>
              </li>
            </ul>
            <div className={styles.downloadArea}>
              <div className={styles.actions}>
                <a href="https://apps.apple.com/tr/app/fujibas-fotograf-bask-kare/id1143428761" target="_blank" rel="noopener noreferrer">
                  <img src={IosDownloadIcon} alt="ios" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.kozton.frent" target="_blank" rel="noopener noreferrer">
                  <img src={GoogleDownloadIcon} alt="Google" />
                </a>
              </div>
            </div>
            <p>{t("copy")}</p>
          </Col>
        </Row>
        {rentInfoModalVisible && (
          <InfoModal
            dataName="rent-info"
            title={t("aracKiralamaRehberi")}
            onClose={() => setRentInfoModalVisible(false)}
          />
        )}
        {rentTermsModalVisible && (
          <InfoModal
            dataName="rental-agreement"
            title={t("kiralamaSozlesmesi")}
            onClose={() => setRentTermsModalVisible(false)}
          />
        )}
        {aboutModalVisible && (
          <InfoModal
            dataName="about-us"
            title={t("hakkimizda")}
            onClose={() => setAboutModalVisible(false)}
          />
        )}
        {termsConditionsVisible && (
          <InfoModal
            title={t("kisiselVeri")}
            dataName="law-of-personal-data"
            onClose={() => setTermsConditionsVisible(false)}
          />
        )}
      </footer>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import moment from 'moment';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Dropdown, Icon } from 'antd';
import { useTranslate } from 'react-translate';
import cx from 'classnames';

import useApp from '../../AppContext';

import styles from './styles.module.scss';

import Logo from '../../../../assets/blue-logo.svg';
import MenuIconClosed from '../../../../assets/menu-icon-closed.svg';
import MenuIconOpened from '../../../../assets/menu-icon-opened.svg';
import ENIcon from '../../../../assets/en.svg';
import TRIcon from '../../../../assets/tr.svg';

const Header = () => {
	const { setLang, lang, me } = useApp();
	const t = useTranslate('common')
	const [isMenuOpen, setMenuStatus] = useState(false);

	const closeMenu = () => {
		setMenuStatus(false);
		document.body.style.overflow = '';
	}

	const langs = {
		en: {
			flag: ENIcon,
			label: t('ingilizce'),
		},
		tr: {
			flag: TRIcon,
			label: t('turkce'),
		}
	};

	const setLanguage = (item) => {
		setLang(item.key);
		localStorage.setItem('lang', item.key);
		moment.updateLocale(item.key, {});
		closeMenu();
	}

	const menu = (
		<Menu onClick={setLanguage} className={styles.langMenu}>
			<Menu.Item key="tr"><img src={TRIcon} alt="tr" />{t('turkce')}</Menu.Item>
			<Menu.Item key="en"><img src={ENIcon} alt="tr" />{t('ingilizce')}</Menu.Item>
		</Menu>
	);

	const langChanger = (
		<Dropdown overlay={menu} placement="bottomCenter">
			<div className={styles.langChangerDropdown}>
				<span><img src={langs[lang].flag} alt={langs[lang].label} /></span>
				<strong>{langs[lang].label} <Icon type="down" /></strong>
			</div>
		</Dropdown>
	);

	return (
		<header className={styles.wrapper}>
			<div className={styles.header}>
				<button
					className={styles.menuOpenButton}
					onClick={() => {
						setMenuStatus(!isMenuOpen)
						window.scrollTo(0, 0)
						if(!isMenuOpen) {
							document.body.style.overflow = 'hidden'
						} else {
							document.body.style.overflow = ''
						}
					}}
				>
					<img src={isMenuOpen ? MenuIconOpened : MenuIconClosed} alt="open/close" />
				</button>
				<div className={styles.logo}>
					<Link to="/" onClick={closeMenu}>
						<img src={Logo} alt="Logo" />
					</Link>
				</div>
				<nav className={cx({ [styles.menuOpened]: isMenuOpen })}>
					<ul>
						<li className={cx(styles.langChanger, styles.mobile)}>
							{langChanger}
						</li>
						<li>
							<NavLink onClick={closeMenu} activeClassName={styles.active} to="/fleet">{t('filo')}</NavLink>
						</li>
						<li>
							<NavLink onClick={closeMenu} activeClassName={styles.active} to="/campaigns">{t('kampanyalar')}</NavLink>
						</li>
						<li>
							<NavLink onClick={closeMenu} activeClassName={styles.active} to="/corporate-rent">{t('kurumsalKiralama')}</NavLink>
						</li>
						<li className={cx(styles.loginButton, {[styles.me]: !!me.id })}>
							{me.id ? (
								<Link onClick={closeMenu} to="/account/details">{t('hesabim')}</Link>
							) : (
								<Link onClick={closeMenu} to="/login">{t('girisYap')}</Link>
							)}
						</li>
						<li className={cx(styles.langChanger, styles.desktop)}>
							{langChanger}
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default Header;


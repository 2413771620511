import { useState } from 'react';
import moment from 'moment';
import { isObject } from 'lodash';
import useApp from '../../../containers/App/AppContext';
import { getSearchParamsQuery, getName } from '../../../utils';

const useSearchForm = (onChange, values) => {
  const { regions } = useApp();
  const getRegionById = (id) => mappedRegions.find(region => region.id === Number(id));
  const mappedRegions = regions.data.map(region => ({
    ...region,
    name: getName(region)
  }));

  const [deliverFromDifferentPlace, setDeliverFromDifferentPlace] = useState(!!values.returnPlace);
  const [errors, setErrors] = useState({});

  const [receivingPlaceVisible, setReceivingPlaceVisible] = useState(undefined);
  const [receivingTimeVisible, setReceivingTimeVisible] = useState(undefined);
  const [returnPlaceVisible, setReturnPlaceVisible] = useState(undefined);
  const [returnTimeVisible, setReturnTimeVisible] = useState(undefined);

  const [receivingPlace, setReceivingPlace] = useState(getRegionById(values.receivingPlace) || {});
  const [returnPlace, setReturnPlace] = useState(getRegionById(values.returnPlace) || {});

  const [receivingTime, setReceivingTime] = useState(values.receivingTime ? values.receivingTime : {});
  const [returnTime, setReturnTime] = useState(values.returnTime ? values.returnTime : {});

  const [focusedInput, setFocusedInput] = useState(null);
  const [focusedSingleDateInput, setFocusedSingleDateInput] = useState(null);

  const [receivingDate, setReceivingDate] = useState(values.receivingDate || undefined);
  const [returnDate, setReturnDate] = useState(values.returnDate || undefined);

  const handleReceivingPlaceChange = value => {
    setReceivingPlace(value);
    setErrors({ ...errors, receivingPlace: false });
    if(deliverFromDifferentPlace) {
      setReturnPlaceVisible(true);
    } else {
      setFocusedSingleDateInput(true);
    }
  };

  const handleReturnPlaceChange = value => {
    setReturnPlace(value);
    setFocusedSingleDateInput(true);
    setErrors({ ...errors, returnPlace: false });
  };

  const handleReceivingTimeChange = value => {
    setReceivingTime(value);
    setReturnDate(null);
    setFocusedInput('endDate');
    setErrors({ ...errors, receivingTime: false });
  };

  const handleReturnTimeChange = value => {
    setReturnTime(value);
    setReturnTimeVisible(false);
    submitData({ returnTime: value });
    setErrors({ ...errors, returnTime: false });
  };

  const handleReturnDateChange = ({ endDate }) => {
    setReturnDate(endDate.format());
    setReturnTimeVisible(true);
    setErrors({ ...errors, returnDate: false });
  };

  const handleReceivingDateChange = value => {
    setReceivingDate(value.format());
    setReceivingTimeVisible(true);
    setErrors({ ...errors, receivingDate: false });
  };

  const submitData = (data = {}) => {
    const requiredFields = {
      ...(deliverFromDifferentPlace ? { returnPlace } : {}),
      returnDate: moment(returnDate).isSame(receivingDate) ? moment(returnDate).add(1, 'days') : returnDate,
      receivingDate,
      returnTime,
      receivingTime,
      receivingPlace,
      ...data,
    };

    const fields = Object.keys(requiredFields).map(field => {
      const obj = requiredFields[field];
      const status = isObject(obj) ? obj.id : obj
      return {
        field,
        status: !!moment.isMoment(obj) || !!status
      };
    });

    const filteredFields = fields.filter(field => !field.status);

    if(filteredFields.length === 0) {
      onChange(getSearchParamsQuery(requiredFields));
      setErrors({});
    } else {
      const errorObject = {};
      filteredFields.forEach(element => {
        errorObject[element.field] = true;
      });

      setErrors(errorObject)
    }
  };

  return {
    submitData,

    handleReceivingPlaceChange,
    handleReturnPlaceChange,
    handleReceivingTimeChange,
    handleReceivingDateChange,
    handleReturnTimeChange,
    handleReturnDateChange,

    setReceivingTimeVisible,
    setFocusedInput,
    setReceivingDate,
    setFocusedSingleDateInput,
    setReturnDate,
    setDeliverFromDifferentPlace,
    setReceivingPlaceVisible,
    setReturnPlaceVisible,
    setReturnTimeVisible,

    returnDate,
    returnTime,
    receivingTimeVisible,
    receivingTime,
    receivingDate,
    focusedSingleDateInput,
    errors,
    focusedInput,
    returnTimeVisible,
    returnPlaceVisible,
    receivingPlace,
    mappedRegions,
    returnPlace,
    receivingPlaceVisible,
    deliverFromDifferentPlace,
  };
}

export default useSearchForm;
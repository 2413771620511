import { validateEmail } from '../../utils';
import { isValidPhoneNumber } from 'react-phone-number-input'

const validateUserForm = (data, setData) => {
  const feildErrors = {};

  const hasErros = Object.keys(data.user).map((field) => {
    if(!data.user[field]) {
      feildErrors[field] = true;
      return true;
    }

    if(field === 'email' && !validateEmail(data.user[field])) {
      feildErrors[field] = true;
      return true;
    }

    if(field === 'mobile' && !isValidPhoneNumber(data.user[field])) {
      feildErrors[field] = true;
      return true;
    }

    feildErrors[field] = false;
    return false;
  }).filter(field => field);

  setData({
    ...data,
    userErrors: {
      ...data.userErrors,
      ...feildErrors,
    }
  });

  return !hasErros.length;
}

const validateBillingForm = (corporateBillInfo, setCorporateBillInfoErrors, corporateBillInfoErrors) => {
  const feildErrors = {};

  const hasErros = Object.keys(corporateBillInfo).map((field) => {
    if(!corporateBillInfo[field]) {
      feildErrors[field] = true;
      return true;
    }

    feildErrors[field] = false;
    return false;
  }).filter(field => field);

  setCorporateBillInfoErrors({
    ...corporateBillInfoErrors,
    ...feildErrors,
  });

  return !hasErros.length; 
};

const validatePaymentForm = (data, setData) => {
  const feildErrors = {};

  const hasErros = Object.keys(data.card).map((field) => {
    if(!data.card[field]) {
      feildErrors[field] = true;
      return true;
    }

    feildErrors[field] = false;
    return false;
  }).filter(field => field);

  setData({
    ...data,
    cardErrors: {
      ...data.cardErrors,
      ...feildErrors,
    }
    
  });

  return !hasErros.length;
}
export {
  validatePaymentForm,
  validateUserForm,
  validateBillingForm,
}
import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useTranslate } from 'react-translate';
import { DateRangePicker } from 'react-dates';
import { SearchInList } from '../../components';
import { getTimes } from './SearchForm.utils';

import styles from './SearchForm.module.scss';

import TimeIcon from '../../../assets/time-icon.svg'

const times = getTimes();

const ReturnDateAndTime = (props) => {
  const t = useTranslate('common');
  const {
    errors,
    returnDate,
    returnTime,
    focusedInput,
    setFocusedInput,
    handleReturnTimeChange,
    returnTimeVisible,
    receivingDate,
    setReturnTimeVisible,
    handleReturnDateChange,
  } = props;

  const mappedTimes = times.map((time) => ({
    id: time,
    name: time,
  }));

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className={styles.selectDateAndTimeWrapper}>
      <div className={cx(styles.pickerWrapper, styles.field, styles.rangePicker, { [styles.errorField]: errors.returnDate })}>
        <span className={styles.label}>{t('donusTarihi')}</span>
        <DateRangePicker
          readOnly
          startDate={receivingDate ? moment(receivingDate) : moment()}
          startDateId="startDate"
          endDate={returnDate ? moment(returnDate) : null}
          endDateId="endDate"
          numberOfMonths={isTabletOrMobile ? 1 : 2}
          displayFormat="DD.MM.YYYY"
          endDatePlaceholderText={t('tarih')}
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel={true}
          enableOutsideDays
          isOutsideRange={day => {
            return moment(day).isSame(moment(receivingDate), 'day') || day.isBefore(moment(receivingDate).subtract(0, 'd')) || day.isAfter(moment().add(3, 'M')) || day.isoWeekday() === 7;
          }}
          onDatesChange={handleReturnDateChange}
          focusedInput={focusedInput}
          onFocusChange={focused_Input => setFocusedInput(focused_Input)}
          minimumNights={0}
        />
        {errors.returnDate && (
          <div className={styles.error}>{t('gerekliAlan')}</div>
        )}
      </div>
      <div className={cx(styles.selectWrapper, styles.selectTimeWrapper, styles.field)}>
        <span className={styles.label}>{t('donusSaati')}</span>
        <div className={cx(styles.fakeInput, styles.time, {[styles.active]: !!returnTime.name, [styles.errorField]: errors.returnTime })}>
          <button onClick={() => setReturnTimeVisible(true)}>
            <img src={TimeIcon} alt="" />
            <span>{returnTime.name || t('saat')}</span>
          </button>
          {errors.returnTime && (
            <div className={styles.error}>{t('gerekliAlan')}</div>
          )}
          { returnTimeVisible && (
            <SearchInList
              searchVisible={false}
              type="time"
              list={mappedTimes}
              visible={returnTimeVisible}
              setVisible={setReturnTimeVisible}
              setData={handleReturnTimeChange}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ReturnDateAndTime;
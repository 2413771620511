import request from '../../utils/request';

const rentVehicle = async (options = {}) => request('rent', { method: 'POST', ...options});
const fetchRentedVehicle = async (id, options = {}) => request(`rent/${id}`, {...options});
const searchRent = async (options = {}) => request('rent/search', { method: 'POST', ...options});

export {
  fetchRentedVehicle,
  searchRent,
  rentVehicle,
}
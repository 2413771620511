import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../Home/Home';
import Reservation from '../Reservation';
import Vehicles from '../Vehicles';
import Login from '../Login';
import Account from '../Account';
import Campaigns from '../Campaigns';
import CampaignDetail from '../Campaigns/Details';
import Fleet from '../Fleet/Fleet';
import CorporateRent from '../CorporateRent/CorporateRent';
import Contact from '../Contact/Contact';
import Faq from '../Faq/Faq';
import ShowRezervation from '../ShowRezervation/ShowRezervation';


const Routes = () => {
	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path="/faq" component={Faq} />
			<Route path="/contact" component={Contact} />
			<Route path="/account" component={Account} />
			<Route path="/show-reservation" component={ShowRezervation} />
			<Route exact path="/login" component={Login} />
			<Route exact path="/search" component={Vehicles} />
			<Route exact path="/fleet" component={Fleet} />
			<Route exact path="/corporate-rent" component={CorporateRent} />
			<Route path="/reservation/:vehicleId" component={Reservation} />
			<Route exact path="/campaigns" component={Campaigns} />
			<Route path="/campaigns/:campaignId" component={CampaignDetail} />
		</Switch>
	);
};

export default Routes;

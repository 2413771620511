import React, { useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { Spin } from 'antd';
import { PhoneNumber, CheckBox, Button, ErrorBlock, Helmet, InfoModal } from '../../common/components';
import { useTranslate } from 'react-translate';
import request from '../../utils/request';
import appUse from '../App/AppContext';

import styles from './Login.module.scss';

import Banner from '../../assets/login-banner.jpg';

const Login = (props) => {
  const t = useTranslate('login');
  const tCm = useTranslate('common');

  const { getMe } = appUse();

  const [loading, setLoading] = useState(false);
  const [userAgreeVisible, setUserAgreeVisible] = useState(false);
  const [termsConditionsVisible, setTermsConditionsVisible] = useState(false);

  const [condition, setCondition] = useState(false);
  const [mobile, setMobile] = useState();
  const [code, setCode] = useState();
  const [loginCodeSuccess, setLoginCodeSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const getLoginCode = async () => {
    if(!condition) {
      return setErrors({ ...errors, condition: true });
    }

    if(!mobile || !isValidPhoneNumber(mobile)) {
      return setErrors({ ...errors, mobile: true, condition: false });
    }
    setLoading(true);
    const phone = parsePhoneNumber(mobile || '');
    const res = await request('auth/mobile', {
      method: 'POST',
      data: { mobile: `${phone.countryCallingCode}${phone.nationalNumber}`, }
    });

    if(res.errors) {
      setLoading(false);
      return setErrors({ ...errors, mobile: true });
    }
    setLoading(false);
    return setLoginCodeSuccess(true);
  };

  const login = async () => {
    if(!code) {
      return setErrors({ code: true });
    }
    setLoading(true);

    const phone = parsePhoneNumber(mobile || '');
    const res = await request('auth/code', {
      method: 'POST',
      data: { 
        mobile: `${phone.countryCallingCode}${phone.nationalNumber}`,
        login_code: code
      }
    });

    if(res.errors) {
      setLoading(false);
      return setErrors({ code:  res.errors.login_code[0] });
    }
    localStorage.setItem('token', res.data.data.api_token);
    await getMe();
    setLoading(false);
    props.history.push('/account/details');
  }

  return (
    <Spin spinning={loading} delay={200}>
      <Helmet title={tCm('girisYap')} />
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <h1>{t('girisKayit')}</h1>
          <div className={styles.banner} style={{ backgroundImage: `url(${Banner})`}} />
          <div className={styles.bannerInner}>
            <p>{t('resimAciklama')}</p>
            <div className={styles.form}>
              {!loginCodeSuccess ? (
                <div className={styles.fields}>
                <h3>{t('girisYap')}</h3>
                <div className={cx('formItem', {[styles.error]: errors.mobile })}>
                  <label>{t('gsm')}</label>
                  <PhoneNumber
                    onChange={setMobile}
                    value={mobile}
                  />
                  <ErrorBlock show={errors.mobile} name="phone" value={mobile} />
                </div>
                <div className={cx({[styles.error]: errors.condition })}>
                  <CheckBox
                    checked={condition}
                    rounded
                    onChange={() => setCondition(!condition)}
                  >
                    <button onClick={() => setUserAgreeVisible(true)}>
                      {t('katilim')}
                    </button>
                      {t('ve')}
                    <button onClick={() => setTermsConditionsVisible(true)}>
                      {t('KVK')}
                    </button>
                    {t('kabulEdiyorum')}
                    <div className={styles.errorText}>{t('kosulKabul')}</div>
                  </CheckBox>
                </div>
              </div>
              ) : (
                <div className={cx(styles.fields, styles.codeWrapper)}>
                  <div className={cx('formItem', {[styles.error]: errors.code })}>
                    <label>{t('kodLabel')}</label>
                    <input
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                      placeholder="* * * *"
                      type="password"
                      maxLength={4}
                    />
                    <ErrorBlock show={errors.code} errorText={errors.code} value={code} />
                  </div>
                </div>
              )}
              <Button
                round
                type="primary"
                onClick={() => {
                  if(!loginCodeSuccess) {
                    return getLoginCode()
                  }
                  return login();
                }}
              >
                {loginCodeSuccess ? (
                 t('girisYap') 
                ) : (
                  t('kodGonder')
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {termsConditionsVisible && (
        <InfoModal
          title={t('kisiselVeri')}
          dataName="law-of-personal-data"
          onClose={() => setTermsConditionsVisible(false)}
        />
      )}
      {userAgreeVisible && (
        <InfoModal
          title={tCm('katilimKosullari')}
          dataName="user-agreement"
          onClose={() => setUserAgreeVisible(false)}
        />
      )}
    </Spin>
  )
}

export default withRouter(Login);

import React from 'react';
import { useTranslate } from 'react-translate';
import {
  VehicleCard, ExtrasDisplay, DriverInfo, PaymentCheckbox
} from '../../common/components';
import appUse from '../App/AppContext';

import styles from './Account.module.scss';

const RentItem = ({ rent, openBillingModalVisible, setBillingValues }) => {
  const t = useTranslate('vehicle');
  const { segments } = appUse();
  const getSegment = (id) => segments.data.find(segment => segment.id === id) || {};

  return (
    <div className={styles.rent}>
      <div className={styles.rentHeader}>
        <h3>{t('rezNo')} {rent.code}</h3>
        <div>
          <span>{t('durum')}: </span>
          <span className={styles[rent.status]}>{t(rent.status)}</span>
        </div>
      </div>
      <VehicleCard
        key={rent.id}
        item={rent.vehicle}
        segment={getSegment(rent.vehicle && rent.vehicle.vehicle_segment_id)}
        state="reservation"
        params={{
          dropoff_place: rent.dropoff_region.id,
          pickup_place: rent.pickup_region.id,
          pickup_time: rent.pickup_time,
          dropoff_time: rent.dropoff_time,
          dropoff_time_readable: rent.dropoff_time_readable,
          pickup_time_readable: rent.pickup_time_readable,
        }}
        prices={rent.prices}
      />
      {!!rent.extras.length && (
        <ExtrasDisplay
          extras={rent.extras.map(dt => ({ ...dt, ...dt.extra }))}
        />
      )}
      <DriverInfo onChange={() => {}} userInfo={rent.driver} errors={{}} readOnly />
      <PaymentCheckbox
        noCheckbox
        method={rent.payment_method}
        checked
      />
      {rent.invoice && !!rent.invoice.is_corporate && (
        <button
          onClick={() => {
            const invoice = rent.invoice || {};
            setBillingValues({
              title: invoice.billing_title,
              token: invoice.billing_token,
              office: invoice.billing_office,
              address: invoice.billing_address,
            });
            openBillingModalVisible();
          }}
          className={styles.openModalButton}
        >
          {t('kurumsalFaturaBilgileri')}
        </button>
      )}
    </div>
  );
};

export default RentItem;
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { BrowserRouter as Router } from 'react-router-dom';
import { TranslatorProvider } from 'react-translate';
import { AppProvider } from './containers/App/AppContext';
import Routes from './containers/App/Routes';
import Header from './containers/App/components/Header';
import Footer from './containers/App/components/Footer';
import { fetchRegions, fetchSettings, fetchMe } from './services';
import { getToken } from './utils';
import { fetchVehicleSegments } from './containers/Vehicles/services';
import { ScrollToTop, Helmet } from './common/components';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './App.css';

import en from './locales/en.json';
import tr from './locales/tr.json';

require('moment/locale/tr');
require('moment/locale/en-gb');

const translations = {
  en,
  tr,
};

const userLang = localStorage.getItem('lang');
const retrievedMe = localStorage.getItem('me');

moment.locale(userLang || 'tr');

function App() {
  const token = getToken();
  const [lang, setLang] = useState(userLang || 'tr');
  const [regions, setRegions] = useState({ data: [] });
  const [segments, setSegments] = useState({ data: [] });
  const [settings, setSettings] = useState({ data: [] });
  const [me, setMe] = useState({ ...JSON.parse(retrievedMe) });

  const translation = translations[lang];

  const getRegions = async () => {
    const res = await fetchRegions();
    setRegions(res.data);
  };

  const getSettings = async () => {
    const res = await fetchSettings();
    setSettings(res.data);
  };

  const getSegments = async () => {
    const res = await fetchVehicleSegments();
    setSegments(res.data);
  };

  const getMe = async () => {
    const res = await fetchMe();
    setMe(res.data.data);
    localStorage.setItem('me', JSON.stringify(res.data.data));
  };

  useEffect(() => {
    getRegions();
    getSegments();
    getSettings();
  }, []);

  useEffect(() => {
    if(token) {
      getMe();
    }
  }, [token]);

  const value = {
    lang, setLang, regions, segments, settings, getMe, me, setMe
  };

  return (
    <AppProvider value={value}>
      <SmartBanner title="f-Rent" position="top" />
      <TranslatorProvider translations={{ locale: lang, ...translation }}>
      <Helmet title="f-rent - " />
        <Router>
          <ScrollToTop>
            <Header />
            <main>
              <Routes />
            </main>
            <Footer />
          </ScrollToTop>
        </Router>
      </TranslatorProvider>
    </AppProvider>
  );
}

export default App;

import React from 'react';
import { useTranslate } from 'react-translate';
import { Popover } from 'antd';

import styles from './Extras.module.scss';

import InfoIcon from '../../../assets/info.svg';
import GrayTLIcon from '../../../assets/gray-tl.svg';
import { getKeyByLang } from '../../../utils';

const ExtrasDisplay = ({ extras }) => {
  const t = useTranslate('vehicle');

  const fullInsuranceExtra = extras.find(dt => dt.type === 'full-insurance');
  const semiInsuranceExtra = extras.find(dt => dt.type === 'semi-insurance');

  const fullInsuranceExtraToolTipContent = (
    <div>
      <p>{getKeyByLang(fullInsuranceExtra, 'description')}</p>
    </div>
  );
  const semiInsuranceExtraToolTipContent = (
    <div>
      <p>{getKeyByLang(semiInsuranceExtra, 'description')}</p>
    </div>
  );

  const rentTermsTooltip = (
    <div className={styles.rentTermsTooltip}>
      <h3>{t('kiralamaSartlari')}</h3>
      <ul>
        <li>{t('depozitoBilgisi')}: <strong>500 </strong>₺</li>
        <li>{t('minimumSurucuYasi')}: <strong>21</strong></li>
        <li>{t('minimumEhliyetYasi')}: <strong>2</strong></li>
      </ul>
      <p>
        {t('rentTermsTooltipAciklama')}
      </p>
    </div>
  );

  return (
    <div className={styles.extrasDisplay}>
      <h2>{t('aracEkstraSecimi')}</h2>
      <div className={styles.wrapper}>
        <ul>
          {fullInsuranceExtra && (
            <li>
              {getKeyByLang(fullInsuranceExtra, 'name')}
              <Popover content={fullInsuranceExtraToolTipContent}>
                <span><img src={InfoIcon} alt="info" /></span>
              </Popover>
              <strong>{fullInsuranceExtra.price}</strong>
              <img src={GrayTLIcon} alt="TL" />
            </li>
          )}
          {semiInsuranceExtra && (
            <li>
              {getKeyByLang(semiInsuranceExtra, 'name')}
              <Popover content={semiInsuranceExtraToolTipContent}>
                <span><img src={InfoIcon} alt="info" /></span>
              </Popover>
              <strong>{semiInsuranceExtra.price}</strong>
              <img src={GrayTLIcon} alt="TL" />
            </li>
          )}
          {extras.map(extra => {
            if(extra.type === 'full-insurance' || extra.type === 'semi-insurance') return null;
            return (
              <li key={extra.id}>
                {getKeyByLang(extra, 'name')}
                <strong>{extra.price * extra.quantity}</strong>
                <img src={GrayTLIcon} alt="TL" />
              </li>
            )
          })}
          <li className={styles.vehicleRent}>
            <Popover content={rentTermsTooltip} placement="left">
              <span><img src={InfoIcon} alt="info" /> {t('kiralamaSartlari')}</span>
            </Popover>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ExtrasDisplay;
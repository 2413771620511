import React from 'react';
import { Collapse, Checkbox } from 'antd';
import cx from 'classnames';
import { useTranslate } from 'react-translate';

import styles from './Vehicles.module.scss';
import checkboxStyles from '../../common/components/CheckBox/CheckBox.module.scss';
import { getName } from '../../utils';

const { Panel } = Collapse;

const Sidebar = ({ segments, onChange, queries, visible }) => {
  const t = useTranslate('vehicle');

  const vehicleFuelTypes = [
    { label: t('dizel'), value: 'diesel' },
    { label: t('benzinli'), value: 'gas' },
  ];

  const vehicleTransmissions = [
    { label: t('otomatikVites'), value: 'automatic' },
    { label: t('duzVites'), value: 'manuel' },
  ]
  
  const vehicleSegments = segments.map(segment => ({
    label: getName(segment),
    value: segment.id,
  }));

  const driverSizes = [
    { label: t('1veya2kisi'), value: '1-2person' },
    { label: t('kucukGrup'), value: 'small-group' },
    { label: t('buyukGrup'), value: 'big-group' },
  ];

  return (
    <div className={cx(styles.sideBar, {[styles.open]: visible })}>
      <Collapse
        bordered={false}
        defaultActiveKey={['1', '2', '3']}
        expandIconPosition="right"
      >
        <Panel header={t('aracOzellikleri')} key="1">
          <Checkbox.Group
            options={vehicleFuelTypes}
            onChange={(values) => onChange(values, 'fuel_type')}
            className={cx(checkboxStyles.root, styles.checkboxes)}
            value={queries.fuel_type}
          />
          <Checkbox.Group
            options={vehicleTransmissions}
            onChange={(values) => onChange(values, 'transmission')}
            className={cx(checkboxStyles.root, styles.checkboxes)}
            value={queries.transmission}
          />
        </Panel>
        <Panel header={t('aracSinifi')} key="2">
          <Checkbox.Group
            options={vehicleSegments}
            onChange={(values) => onChange(values, 'segment_id')}
            className={cx(checkboxStyles.root, styles.checkboxes)}
            value={queries.segment_id.map(id => Number(id))}
          />
        </Panel>
        <Panel header={t('surucuSinifi')} key="3">
          <Checkbox.Group
            options={driverSizes}
            onChange={(values) => onChange(values, 'size')}
            className={cx(checkboxStyles.root, styles.checkboxes)}
            value={queries.size}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default Sidebar;

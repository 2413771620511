import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import moment from "moment";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";
import { useTranslate } from "react-translate";
import { isArray } from "lodash";
import { Spin, Empty } from "antd";

import {
  VehicleCard,
  Button,
  SearchForm,
  Helmet
} from "../../common/components";
import Sidebar from "./Sidebar";
import { searchVehicle } from "./services";
import appUse from "../App/AppContext";
import {
  getSearchParamsValues,
  getSearchParamsQuery,
  getName
} from "../../utils";

import styles from "./Vehicles.module.scss";

import RefreshIcon from "../../assets/refresh.svg";

const getQueries = (data) => {
  const dt = { ...data };

  if (data.fuel_type && !isArray(data.fuel_type)) {
    dt.fuel_type = [data.fuel_type];
  }
  if (data.size && !isArray(data.size)) {
    dt.size = [data.size];
  }
  return dt;
};

const Vehicles = (props) => {
  const t = useTranslate("vehicle");
  const parsedSearch = queryString.parse(props.location.search);
  const [vehicles, setVehicles] = useState({ data: [], meta: {} });
  const [errors, setErrors] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 15000,
    page: 1,
    hasMore: false
  });
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState({
    transmission: [],
    segment_id: [],
    size: [],
    fuel_type: [],
    sort: "asc",
    ...getQueries(queryString.parse(props.location.search))
  });
  const { segments, regions } = appUse();

  const getRegionById = (id) =>
    mappedRegions.find((region) => region.id === Number(id));
  const mappedRegions = regions.data.map((region) => ({
    ...region,
    name: getName(region)
  }));

  const pickupPlace = getRegionById(parsedSearch.pickup_place);
  const dropoffPlace = getRegionById(parsedSearch.dropoff_place) || pickupPlace;
  const pickupTime = moment(parsedSearch.pickup_time);
  const dropoffTime = moment(parsedSearch.dropoff_time);

  const sortOnChange = (e) => {
    setQueries({ ...queries, sort: e.target.value });
    changeUrl({ ...queries, sort: e.target.value });
  };

  const onSidebarCheckboxChange = (checkedValues, group) => {
    setQueries({ ...queries, [group]: checkedValues });
    changeUrl({ ...queries, [group]: checkedValues });
  };

  const getVehicles = async (isPageChanged) => {
    setLoading(true);
    const values = getSearchParamsValues(
      queryString.parse(props.location.search)
    );
    const params = getSearchParamsQuery(values);
    const res = await searchVehicle({
      data: {
        limit: pagination.limit,
        page: pagination.page,
        ...queries,
        ...params
      }
    });

    if (res.errors) {
      setLoading(false);
      const errs = Object.keys(res.errors).map((err) => {
        return res.errors[err];
      });
      return setErrors(errs);
    }

    let newData = {
      ...res.data,
      data: res.data.data
    };
    if (isPageChanged) {
      newData = {
        ...res.data,
        data: [...vehicles.data, ...res.data.data]
      };
    }
    setVehicles(newData);
    setLoading(false);

    if (pagination.page !== res.data.meta.last_page) {
      setPagination({ ...pagination, hasMore: true });
    }
  };

  const getSegment = (id) =>
    segments.data.find((segment) => segment.id === id) || {};

  useEffect(() => {
    getVehicles();
  }, [props.location.search]); // eslint-disable-line

  useEffect(() => {
    getVehicles(true);
  }, [pagination.page]); // eslint-disable-line

  const changeUrl = (params) => {
    const stringified = queryString.stringify(params);
    props.history.replace(`/search?${stringified}`);
  };

  return (
    <Spin spinning={loading} delay={50}>
      <Helmet title={t("araclar")} />
      <div className={styles.page}>
        <MediaQuery minWidth={991}>
          <div className={styles.searchForm}>
            {regions.data && !!regions.data.length && (
              <SearchForm
                values={getSearchParamsValues(
                  queryString.parse(props.location.search)
                )}
                onChange={(values) => {
                  changeUrl(values);
                }}
              />
            )}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={991}>
          <div className={styles.filters}>
            <div className={styles.dates}>
              <div className={styles.refresh}>
                <button
                  onClick={() => {
                    props.history.replace(`/${props.history.location.search}`);
                  }}
                >
                  <img src={RefreshIcon} alt="refresh" />
                </button>
              </div>
              <div className={styles.date}>
                <div>
                  {pickupTime.format("DD.MM.YYYY")} -{" "}
                  {pickupTime.format("HH:mm")}
                </div>
                {pickupPlace && <span>{pickupPlace.name}</span>}
              </div>
              <div className={styles.date}>
                <div>
                  {dropoffTime.format("DD.MM.YYYY")} -{" "}
                  {dropoffTime.format("HH:mm")}
                </div>
                {dropoffPlace && <span>{dropoffPlace.name}</span>}
              </div>
            </div>
            <div className={styles.actions}>
              <div className={styles.field}>
                <Button
                  type="primary"
                  onClick={() => setSidebarVisible(!sidebarVisible)}
                >
                  {t("filtre")}
                </Button>
              </div>
              <div className={styles.field}>
                <select onChange={sortOnChange} value={queries.sort || "asc"}>
                  <option value="desc">{t("dusukFiyat")}</option>
                  <option value="asc">{t("yuksekFiyat")}</option>
                </select>
              </div>
            </div>
          </div>
        </MediaQuery>
        <div className={styles.root}>
          <Sidebar
            queries={queries}
            segments={segments.data}
            onChange={onSidebarCheckboxChange}
            visible={sidebarVisible}
          />
          <div className={styles.content}>
            <MediaQuery minWidth={991}>
              <div className={styles.field}>
                <select onChange={sortOnChange} value={queries.sort || "asc"}>
                  <option value="asc">{t("dusukFiyat")}</option>
                  <option value="desc">{t("yuksekFiyat")}</option>
                </select>
              </div>
            </MediaQuery>
            {errors.map((error) => error)}
            {!loading && !Boolean(vehicles.data.length) && (
              <div>
                <Empty description={t("aramaSonucu")} />
              </div>
            )}
            <InfiniteScroll
              dataLength={vehicles.data.length}
              next={() =>
                setPagination({
                  ...pagination,
                  page: pagination.page + 1,
                  hasMore: false
                })
              }
              hasMore={pagination.hasMore}
              scrollThreshold="50px"
            >
              {vehicles.data.map((vehicle) => (
                <VehicleCard
                  key={vehicle.id}
                  item={vehicle}
                  segment={getSegment(vehicle.vehicle_segment_id)}
                  params={queryString.parse(props.location.search)}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default withRouter(Vehicles);

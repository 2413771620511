import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { useTranslate } from 'react-translate';

import Reservations from './Reservations'
import Details from './Details'
import styles from './Account.module.scss';
import { Helmet } from '../../common/components';

const Account = () => {
  const t = useTranslate('account');

  return (
    <div className={styles.root}>
      <Helmet title={t('hesabim')} />
      <div className={styles.wrapper}>
        <h2>{t('hesabim')}</h2>
        <ul>
          <li>
            <NavLink activeClassName={styles.active} to="/account/details">{t('hesapDetay')}</NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.active} to="/account/reservations">{t('rezervasyonlar')}</NavLink>
          </li>
        </ul>
        <Route path="/account/reservations" render={() => <Reservations />} />
        <Route path="/account/details" render={() => <Details />} />
      </div>
    </div>
  );
};

export default Account;

import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

const ReservationSteps = ({ stepStatuses, reservationSteps }) => {
  return (
    <Steps>
      {reservationSteps.map(step => {
        return (
          <Step
            key={step.id}
            status={stepStatuses[step.id]}
            title={step.name}
            icon={<img src={step.icons[stepStatuses[step.id]]} alt="step" />}
          />
        );
      })}
    </Steps>
  );
};

export default ReservationSteps;

import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useTranslate } from 'react-translate';
import { withRouter } from 'react-router-dom';

import { Button, Helmet } from '../../common/components';

import useCampaigns from './useCampaigns'

import styles from './Campaigns.module.scss';

const Campaigns = ({ history }) => {
  const t = useTranslate('campaigns')
  const tCm = useTranslate('common')

  const {
    getKeyByLang,

    loading,
    campaigns,
  } = useCampaigns();
  

  return (
    <Spin spinning={loading} delay={200}>
      <Helmet title={tCm('kampanyalar')} />
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.campaigns}>
            {campaigns.data.map(camp => (
              <div key={camp.id} className={styles.column}>
                <div className={styles.campaign}>
                  <div className={styles.campaignContent} style={{ backgroundImage: `url(${camp.image_url})` }}>
                    <p>{getKeyByLang(camp, 'title')}</p>
                    <Button
                      type="primary"
                      round
                      onClick={() => history.push(`/campaigns/${camp.id}`)}
                    >
                      {t('hemenKesfet')}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default withRouter(Campaigns);
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import { fetchRents } from './services';
import CorporateBillInfo from '../../common/components/Extras/CorporateBillInfo';

import styles from './Account.module.scss';
import RentItem from './RentItem';

const Reservations = () => {
  const [loading, setLoading] = useState(true);
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [rents, setRents] = useState({ data: [] });
  const [billingValues, setBillingValues] = useState({});

  const getRents = async () => {
    const res = await fetchRents({
      params: {
        limit: 99,
        page: 1,
        include: 'vehicle.segment,driver,user,invoice',
        // sort: 'desc',
      }
    });
    setLoading(false);
    setRents(res.data);
  };

  useEffect(() => {
    getRents();
  }, []);

  return (
    <Spin spinning={loading} delay={200}>
      <div className={styles.detailsWrapper}>
        {rents.data.map(rent => (
          <RentItem
            key={rent.id}
            rent={rent}
            setBillingValues={setBillingValues}
            openBillingModalVisible={() => setBillingModalVisible(true)}
          />
        ))}
        {billingModalVisible && (
          <CorporateBillInfo
            readOnly
            onClose={() => setBillingModalVisible(false)}
            values={billingValues}
          />
        )}
      </div>
    </Spin>
  );
};

export default Reservations;

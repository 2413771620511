import React from 'react';
import { Modal } from 'antd';
import SuccessPaymentIcon from '../../../assets/success-payment-icon.svg';
import styles from './SuccessModal.module.scss';

const SuccessModal = ({ text, onClose }) => {
  return (
    <Modal
      onCancel={onClose}
      visible
      width={445}
      footer={null}
      className={styles.successModal}
    >
      <img src={SuccessPaymentIcon} alt="SuccessPaymentIcon" />
      <h1>{text}</h1>
    </Modal>
  );
};

export default SuccessModal;

import React, { useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { parsePhoneNumber } from 'react-phone-number-input'
import { PhoneNumber, Button, Helmet, ErrorBlock, SuccessModal } from '../../common/components';
import { useTranslate } from 'react-translate';
import { validateContact } from '../Contact/Contact';
import request from '../../utils/request';

import styles from './CorporateRent.module.scss';

import Banner from '../../assets/login-banner.jpg';

import LOGO from '../../assets/logo-trans.svg'

const CorporateRent = () => {
  const tVehicle = useTranslate('vehicle');
  const t = useTranslate('corporateRent');
  const tCm = useTranslate('common');
  const tContact = useTranslate('contact');

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    subject: '',
    message: '',
    email: '',
    mobile: '',
    company: '',
    tax_number: '',
  });
  const [errors, setErrors] = useState({});

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  };

  const submit = async () => {
    if(!validateContact(formData, setErrors, errors)) {
      return false
    }
    const mobile = parsePhoneNumber(formData.mobile || '');
    setLoading(true);
    const res = await request('contact', {
      method: 'POST',
      data: {
        ...formData,
        mobile: `${mobile.countryCallingCode}${mobile.nationalNumber}`
      },
    });
    setLoading(false);
    if(res.errors) {
      const errs = {};
      Object.keys(res.errors).map(err => {
        return errs[err] = res.errors[err][0];
      });
      setErrors({ ...errs })
      return setLoading(false);
    }

    setFormData({
      name: '',
      surname: '',
      subject: '',
      message: '',
      email: '',
      mobile: '',
      company: '',
      tax_number: '',
    });
    setModalVisible(true);
  }

  return (
    <Spin spinning={loading} delay={200}>
      <Helmet title={tCm('kurumsalKiralama')} />
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <h1>{t('pageTitle')}</h1>
          <div className={styles.banner} style={{ backgroundImage: `url(${Banner})`}} />
          <div className={styles.bannerInner}>
            <p>{t('pageDescription', { logo: <img src={LOGO} alt="" />})}</p>
            <div className={styles.form}>
              <div className={styles.formItemWrapper}>
                <div className={cx('formItem', {[styles.error]: errors.company })}>
                  <label>{tVehicle('sirketUnvani')}</label>
                  <input
                    placeholder={tVehicle('sirketUnvani')}
                    name="company"
                    onChange={onChange}
                    value={formData.company}
                  />
                  <ErrorBlock show={errors.company} value={formData.company} />
                </div>
                <div className={cx('formItem', {[styles.error]: errors.tax_number })}>
                  <label>{tVehicle('vergiNumarasi')}</label>
                  <input
                    placeholder={tVehicle('vergiNumarasi')}
                    name="tax_number"
                    onChange={onChange}
                    maxLength={10}
                    value={formData.tax_number}
                  />
                  <ErrorBlock show={errors.tax_number} value={formData.tax_number} />
                </div>
              </div>
              <div className={styles.formItemWrapper}>
                <div className={cx('formItem', {[styles.error]: errors.name })}>
                  <label>{tVehicle('ad')}</label>
                  <input
                    placeholder={tVehicle('ad')}
                    name="name"
                    onChange={onChange}
                    value={formData.name}
                  />
                  <ErrorBlock show={errors.name} value={formData.name} />
                </div>
                <div className={cx('formItem', {[styles.error]: errors.surname })}>
                  <label>{tVehicle('soyad')}</label>
                  <input
                    placeholder={tVehicle('soyad')}
                    name="surname"
                    onChange={onChange}
                    value={formData.surname}
                  />
                  <ErrorBlock show={errors.surname} value={formData.surname} />
                </div>
              </div>
              <div className={styles.formItemWrapper}>
                <div className={cx('formItem', {[styles.error]: errors.subject })}>
                  <label>{tContact('konu')}</label>
                  <input
                    placeholder={tContact('konu')}
                    name="subject"
                    onChange={onChange}
                    value={formData.subject}
                  />
                  <ErrorBlock show={errors.subject} value={formData.subject} />
                </div>
                <div className={cx('formItem', {[styles.error]: errors.email })}>
                  <label>{tVehicle('eposta')}</label>
                  <input
                    type="email"
                    placeholder={tVehicle('eposta')}
                    name="email"
                    onChange={onChange}
                    value={formData.email}
                  />
                  <ErrorBlock show={errors.email} value={formData.email} />
                </div>
              </div>
              <div className={styles.formItemWrapper}>
                <div className={cx('formItem', {[styles.error]: errors.mobile })}>
                  <label>{tVehicle('gsm')}</label>
                  <PhoneNumber
                    onChange={(value) => {
                      onChange({
                        target: { value, name: 'mobile' }
                      })
                    }}
                    value={formData.mobile}
                  />
                  <ErrorBlock show={errors.mobile} value={formData.mobile} name="phone" />
                </div>
                <div className={cx('formItem', {[styles.error]: errors.message })}>
                  <label>{tContact('mesaj')}</label>
                  <textarea
                    placeholder={tContact('mesaj')}
                    onChange={onChange}
                    name="message"
                    value={formData.message}
                  />
                  <ErrorBlock show={errors.message} value={formData.message} />
                </div>
              </div>
              <Button round type="primary" onClick={submit}>
                {t('gonder')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {modalVisible && (
        <SuccessModal
          text={tContact('basariliMesaj')}
          onClose={() => setModalVisible(false)}
        />
      )}
    </Spin>
  )
}

export default withRouter(CorporateRent);

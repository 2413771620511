import React from 'react';
import cx from 'classnames';
import { useTranslate } from 'react-translate';

import { PhoneNumber, ErrorBlock } from '../';

import styles from './DriverInfo.module.scss';

const DriverInfo = ({ readOnly, onChange, userInfo, errors, noTitle }) => {
  const t = useTranslate('vehicle');
  const tCm = useTranslate('common');

  return (
    <div className={cx(styles.root, {[styles.readOnly]: readOnly })}>
      {!noTitle && <h2>{t('surucuBilgileri')}</h2>}
      <div className={styles.wrapper}>
        <div className={cx('formItem', {[styles.error]: errors.name })}>
          <label>{tCm('ad')}</label>
          <input
            placeholder={tCm('ad')}
            disabled={readOnly}
            onChange={onChange}
            name="name"
            value={userInfo.name}
          />
          <ErrorBlock show={errors.name} value={userInfo.name} />
        </div>
        <div className={cx('formItem', {[styles.error]: errors.surname })}>
          <label>{tCm('soyad')}</label>
          <input
            placeholder={tCm('soyad')}
            disabled={readOnly}
            onChange={onChange}
            name="surname"
            value={userInfo.surname}
          />
          <ErrorBlock show={errors.surname} value={userInfo.surname} />
        </div>
        <div className={cx('formItem', {[styles.error]: errors.email })}>
          <label>{tCm('eposta')}</label>
          <input
            type="email"
            placeholder={tCm('eposta')}
            disabled={readOnly}
            onChange={onChange}
            name="email"
            value={userInfo.email}
          />
          <ErrorBlock show={errors.email} value={userInfo.email} />
        </div>
        <div className={cx('formItem', {[styles.error]: errors.mobile })}>
          <label>{tCm('gsm')}</label>
          <PhoneNumber
            disabled={readOnly}
            onChange={(value) => {
              onChange({
                target: { value, name: 'mobile' }
              })
            }}
            value={userInfo.mobile}
          />
          <ErrorBlock show={errors.mobile} name="phone" value={userInfo.mobile} />
        </div>
      </div>
    </div>
  );
};

DriverInfo.defaultProps = {
  readOnly: false,
  noTitle: false,
};

export default DriverInfo;
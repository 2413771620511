import React from 'react';
import cx from 'classnames';
import { useTranslate } from 'react-translate';

import { CheckBox } from '../';

import styles from './Payment.module.scss';

import PayByCard from '../../../assets/pay-by-card.svg'
import PayByCash from '../../../assets/pay-cash.svg'

const icons = {
  KKileOdeme: PayByCard,
  teslimdeOde: PayByCash,
};

const PaymentCheckbox = (props) => {
  const t = useTranslate('vehicle');

  const getName = () => {
    return props.method === 'pickup' ? 'teslimdeOde' : 'KKileOdeme';
  };

  return (
    <div className={cx(styles.root, styles.paymentCheckbox)}>
      <h2>{t('odemeBilgileri')}</h2>
      {!props.noCheckbox ? (
        <CheckBox rounded checked={props.checked} onChange={props.onChange}>
          {t('KKileOdeme')} <img src={PayByCard} alt="" />
        </CheckBox>  
      ) : (
        <div>
          {t(getName())} <img src={icons[getName()]} alt="" />
        </div>
      )}
      {props.openModal && (
        <div>
          <button className={styles.openModalButton} onClick={props.openModal}>
            {t('kurumsalFaturaBilgileri')}
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentCheckbox;

import request from '../utils/request';

const fetchRegions = async (options = {}) => request('region', { params: { limit: 9999 }, ...options });
const fetchSettings = async (options = {}) => request('settings', { ...options });
const fetchMe = async (options = {}) => request('self/me', { ...options });

export {
  fetchMe,
  fetchSettings,
  fetchRegions,
}


import React from 'react';
import { useTranslate } from 'react-translate';
import {
  Button
} from '../../common/components';

import styles from './Reservation.module.scss';

import SuccessPaymentIcon from '../../assets/success-payment-icon.svg';

const ResevationSuccessBlock = ({ paymentResponse, history }) => {
  const t = useTranslate('vehicle');
  const isCreditCard = paymentResponse.payment_method === 'credit_card';

  return (
    <div className={styles.successPayment}>
      <img src={SuccessPaymentIcon} alt="SuccessPaymentIcon" />
      {isCreditCard ? (
        <h1>{t('basariliOdemeBaslik')}</h1>
      ) : (
        <h1>{t('basariliTalepBaslik')}</h1>
      )}
      <p>{t('basariliOdemeAciklama')}</p>
      <span>{t('rezNo')} {paymentResponse.code}</span>
      <Button
        type="primary"
        round
        onClick={() => history.push('/')}
      >
        {t('anasayfayaGeriDon')}
      </Button>
    </div>
  );
};

export default ResevationSuccessBlock;
import React from 'react';
import { Row, Col } from 'antd';
import { useTranslate } from 'react-translate';
import styles from './Home.module.scss';

import IosDownloadIcon from '../../assets/ios-download.svg';
import GoogleDownloadIcon from '../../assets/google-download.svg';

const DownloadBanner = () => {
  const commonT = useTranslate('common');
  return (
    <Row>
      <Col>
        <div className={styles.downloadArea}>
          <h2>{commonT('hemenIndirin')}</h2>
          <div className={styles.actions}>
            <a href="https://apps.apple.com/tr/app/fujibas-fotograf-bask-kare/id1143428761" target="_blank" rel="noopener noreferrer">
              <img src={IosDownloadIcon} alt="ios" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.kozton.frent" target="_blank" rel="noopener noreferrer">
              <img src={GoogleDownloadIcon} alt="Google" />
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DownloadBanner;

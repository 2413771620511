import axios from 'axios';
import config from '../config';
import { getLanguage, getToken } from './index';

axios.defaults.baseURL = config.baseUrl;

const request = async(uri, { method = 'GET', data, params } = {}) => {
  const token = getToken();
  const res = axios({
    method: method,
    url: uri,
    data: data,
    params: params,
    headers: {
      'client-id': config.clientId,
      'client-secret': config.clientSecret,
      'Accept-Language': getLanguage(),
      ...(token ? {'Api-Token': token } : {}),
    }
  })
    .then(res => res)
    .catch(error => {
      return {
        errors: error.response ? error.response.data : error.response,
      };
    });

  return res;
}

export default request;
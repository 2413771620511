import React, { useRef } from 'react';
import { Carousel } from 'antd';
import { useTranslate } from 'react-translate';
import { withRouter } from 'react-router-dom';
import { Button } from '../../common/components'
import VehicleImage from '../../assets/filo3.png';
import useCampaigns from '../Campaigns/useCampaigns';
import styles from '../Campaigns/Campaigns.module.scss';

import slideStyles from './Home.module.scss';

const Slider = ({ history }) => {
  const ref = useRef();
  const t = useTranslate('common');

  const {
    getKeyByLang,

    loading,
    campaigns,
  } = useCampaigns();

  return (
    <Carousel
      ref={ref}
      autoplay
      autoplaySpeed={3000}
      className={slideStyles.carousel}
      appendDots={(dots) => (
        <div className={slideStyles.carouselActions}>
          <button
            className={slideStyles.carouselAction}
            onClick={() => ref.current.slick.slickNext()}
          >
            {'<'}
          </button>
          <span>01</span>
          {dots.map(dot => (
            <li className={dot.props.className} key={dot.key}>
              {dot.props.children}
            </li>
          ))}
          <span>0{dots.length}</span>
          <button onClick={() => ref.current.slick.slickPrev()}>
            {'>'}
          </button>
        </div>
      )}
    >
      {campaigns.data.map(camp => (
        <div key={camp.id} className={styles.column}>
          <div className={styles.campaign}>
            <div className={styles.campaignContent} style={{ backgroundImage: `url(${camp.image_url})` }}>
              <p>{getKeyByLang(camp, 'title')}</p>
              <Button
                type="primary"
                round
                onClick={() => history.push(`/campaigns/${camp.id}`)}
              >
                {t('hemenKesfet')}
              </Button>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export default withRouter(Slider);
import React from 'react';
import cx from 'classnames';
import { useTranslate } from 'react-translate';
import { isString } from 'lodash';
import styles from './ErrorBlock.module.scss';

const ErrorBlock = (props) => {
  const t = useTranslate('common');

  const name = props.name || 'standard';

  const texts = {
    phone: t('gecersizTelefon'),
    standard: props.errorText ? props.errorText : t('gerekliAlan'),
    ...(isString(props.show) ? {[name]: props.show } : {})
  };

  return (
    <div className={cx(styles.errorText, {[styles.show]: props.show })}>
      {props.value ? texts[name] : t('gerekliAlan')}
    </div>
  )
}

export default ErrorBlock;
import React from 'react';
import cx from 'classnames';
import { Modal } from 'antd';
import styles from './Modal.module.scss';

const ModalWrapper = ({ title, visible, onClose, children, width, className }) => {
  return (
    <Modal
      className={cx(styles.modal, className)}
      title={title}
      visible={visible}
      onCancel={onClose}
      width={width || 610}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;

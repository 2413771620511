import React, { useRef, cloneElement } from 'react';
import { Carousel } from 'antd';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import styles from './VehicleCard.module.scss';

const Photos = ({ photos, className, divide }) => {
  const ref = useRef();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const defaultDivide = isTabletOrMobile ? 55 : 100;

  return (
    <div className={cx(styles.photos, className)}>
      <Carousel
        ref={ref}
        appendDots={(dots) => (
          <div className={styles.carouselActions}>
            <button
              className={styles.carouselAction}
              onClick={() => ref.current.slick.slickPrev()}
            >
              {'<'}
            </button>
            <span>01</span>
            {dots.map(dot => (
              <li className={dot.props.className} key={dot.key} style={{ width: (divide || defaultDivide) / dots.length  }}>
                {cloneElement(dot.props.children, {
                  key: dot.key.toString(),
                  style: { width: (divide || defaultDivide) / dots.length }
                })}
              </li>
            ))}
            <span>{dots.length > 10 ? '' : '0'}{dots.length}</span>
            <button onClick={() => ref.current.slick.slickNext()}>
              {'>'}
            </button>
          </div>
        )}
      >
        {photos && photos.map(photo => (
          <div key={photo}>
            <img
              src={photo}
              alt="arac foto"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Photos;

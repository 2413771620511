import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useTranslate } from 'react-translate';
import { withRouter, Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { Helmet, Button } from '../../common/components';
import { getKeyByLang } from '../../utils';
import { fetchCampaign } from './services';

import styles from './Campaigns.module.scss';

const CampaignDetail = ({ match, history }) => {
  const t = useTranslate('campaigns')
  const tVehicle = useTranslate('vehicle')

  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState({});

  const getCampaign = async () => {
    const res = await fetchCampaign(match.params.campaignId);
    setCampaign(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getCampaign();
  }, [match.params.campaignId]);

  const allCampaignsLink = <Link className={styles.allCampaings} to="/campaigns">{t('tumKampanyalariGor')}</Link>

  return (
    <Spin spinning={loading} delay={200}>
      <Helmet title={getKeyByLang(campaign, 'title')} />
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <MediaQuery minWidth={991}>
            {allCampaignsLink}
          </MediaQuery>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <h1>{getKeyByLang(campaign, 'title')}</h1>
              <p>{getKeyByLang(campaign, 'description')}</p>
              <Button
                round
                type="primary"
                onClick={() => {
                  history.push('/');
                }}
              >
                {tVehicle('kirala')}
              </Button>
            </div>
            <div className={styles.img}>
              <div className={styles.campaignContent} style={{ backgroundImage: `url(${campaign.image_url})` }} />
            </div>
            <MediaQuery maxWidth={991}>
              {allCampaignsLink}
            </MediaQuery>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default withRouter(CampaignDetail);


import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-translate';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';

import Photos from '../../common/components/VehicleCard/Photos';
import VehicleFeatures from '../../common/components/VehicleCard/VehicleFeatures';

import { fetchVehicles } from '../Vehicles/services';

import styles from './Fleet.module.scss';
import { getName } from '../../utils';
import { Helmet, Button } from '../../common/components';

import BlueTLIcon from '../../assets/blue-tl.svg';

const Fleet = ({ history }) => {
  const t = useTranslate('vehicle');
  const tCm = useTranslate('common');
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);

  const getVehicles = async () => {
    const res = await fetchVehicles({
      params: {
        is_active: true,
        include: 'segment'
      }
    });
    setVehicles(res.data.data);
    setLoading(false);
  }

  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <Spin spinning={loading} delay={200}>
      <Helmet title={tCm('filo')} />
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.vehicles}>

            {vehicles.map(vehicle => (
              <div key={vehicle.id} className={styles.vehicle}>
                <div className={styles.content}>
                  <h1>{getName(vehicle)} <span>{t('veyaBenzeri')}</span></h1>
                  <div className={styles.innerContent}>
                    <Photos photos={vehicle.images_readable} className={styles.photos} divide={62} />
                    <div className={styles.list}>
                      <span className={styles.price}>{t('gunluk')}: {vehicle.prices['1+']} <img src={BlueTLIcon} alt="TL" /></span>
                      <VehicleFeatures properties={vehicle.properties} className={styles.vehicleFeatures} />
                      <div className={styles.segment}>
                        {getName(vehicle.segment)}
                      </div>
                      <Button
                        round
                        type="primary"
                        className={styles.rentButton}
                        onClick={() => {
                          history.push('/');
                        }}
                      >
                        {t('kirala')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </Spin>
  )
}

export default withRouter(Fleet);
import React from 'react';
import Modal from '../Modal/Modal';

import useApp from '../../../containers/App/AppContext';

import styles from './InfoModal.module.scss';

const InfoModal = ({ title, dataName, onClose }) => {
  const { lang } = useApp();
  return (
    <Modal
      visible
      title={title}
      onClose={onClose}
      className={styles.modal}
    >
      <object data={`https://static.frent.com.tr/${dataName}/?lang=${lang}`}>
      </object>
    </Modal>
  );
};

export default InfoModal;


import React from "react";
import { useTranslate } from "react-translate";
import cx from "classnames";
import NumberFormat from "react-number-format";
import Cleave from "cleave.js/react";

import { ErrorBlock } from "../";

import styles from "./Payment.module.scss";
import MasterVisaIcon from "../../../assets/master-visa.png";

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "01";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function cardExpiry(val) {
  let month = limit(val.substring(0, 2), "12");
  let year = val.substring(2, 4);

  return month + (year.length ? "/" + year : "");
}

const Payment = ({ errors, onChange, payment, openModal }) => {
  const t = useTranslate("vehicle");

  const onCreditCardChange = (event) => {
    onChange({
      target: { value: event.target.rawValue, name: event.target.name }
    });
  };

  return (
    <div className={styles.root}>
      <h2>
        {t("odemeBilgileri")} <img src={MasterVisaIcon} alt="master visa" />
      </h2>
      <div className={styles.wrapper}>
        <div className={cx("formItem", { [styles.error]: errors.holdername })}>
          <label>{t("kartSahibininAdi")}</label>
          <input
            placeholder={t("kartSahibininAdi")}
            name="holdername"
            onChange={onChange}
            maxLength="50"
            onkeypress="return /[a-z]/i.test(event.key)"
            value={payment.holdername}
          />
          <ErrorBlock show={errors.holdername} value={payment.holdername} />
        </div>
        <div className={cx("formItem", { [styles.error]: errors.number })}>
          <label>{t("kkNumarasi")}</label>
          {/* <NumberFormat
            format="#### #### #### ####"
            placeholder={t('kkNumarasi')}
            name="number"
            onChange={onChange}
            value={payment.number}
          /> */}
          <Cleave
            name="number"
            value={payment.number}
            placeholder={t("kkNumarasi")}
            options={{
              creditCard: true,
              onCreditCardTypeChanged: (type) => {}
            }}
            onChange={onCreditCardChange}
          />
          <ErrorBlock show={errors.number} value={payment.number} />
        </div>
        <div className={cx("formItem", { [styles.error]: errors.expireDate })}>
          <label>{t("sonKullanmaTarihi")}</label>
          <NumberFormat
            format={cardExpiry}
            placeholder={t("sonKullanmaTarihi")}
            name="expireDate"
            onChange={onChange}
            value={payment.expireDate}
          />
          <ErrorBlock show={errors.expireDate} value={payment.expireDate} />
        </div>
        <div className={cx("formItem", { [styles.error]: errors.email })}>
          <label>{t("CVV")}</label>
          <NumberFormat
            format="###"
            placeholder={t("CVV")}
            name="cvc"
            onChange={onChange}
            value={payment.cvc}
          />
          <ErrorBlock show={errors.cvc} value={payment.cvc} />
        </div>
      </div>
      <button className={styles.openModalButton} onClick={openModal}>
        {t("kurumsalFaturaBilgileri")}
      </button>
    </div>
  );
};

export default Payment;

import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useTranslate } from 'react-translate';
import 'react-phone-number-input/style.css'
import styles from './PhoneNumber.module.scss';

const PhoneNumberInput = ({ value, onChange, disabled }) => {
  const [state, setState] = useState(value || '');
  const t = useTranslate('common')

  return (
    <PhoneInput
      disabled={disabled}
      country="TR"
      placeholder={t('phone')}
      className={styles.root}
      value={value}
      onChange={value => {
        onChange(value);
        setState(value);
      }}
    />
  )
};

export default PhoneNumberInput;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Empty } from 'antd';
import { useTranslate } from 'react-translate';
import { searchRent } from '../Reservation/services';
import CorporateBillInfo from '../../common/components/Extras/CorporateBillInfo';

import styles from './ShowRezervation.module.scss';
import RentItem from '../Account/RentItem';
import { Spin } from 'antd';

const ShowRezervationItem = (props) => {
  const t = useTranslate('showReservation');

  const [rent, setRent] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [billingValues, setBillingValues] = useState({});

  const getRent = async () => {
    setLoading(true);
    try {
      setError(false);
      const res = await searchRent({
        params: {
          include: 'vehicle.segment,driver,user,invoice',
        },
        data: {
          code: props.match.params.reservarionCode
        }
      });
      setRent(res.data.data);
      setLoading(false);
      window.scrollTo(0, 450);
    } catch (error) {
      setError(true);
      setLoading(false);  
    }
  }

  useEffect(() => {
    getRent();
  }, [props.match.params.reservarionCode]);

  return (
    <Spin spinning={loading}>
      <div className={styles.reservationDetail}>
        {error && (
          <Empty style={{ paddingBottom: 90 }} description={t('noData')} />
        )}
        {rent.id && (
          <RentItem
            rent={rent}
            setBillingValues={setBillingValues}
            openBillingModalVisible={() => setBillingModalVisible(true)}
          />
        )}
        {billingModalVisible && (
          <CorporateBillInfo
            readOnly
            onClose={() => setBillingModalVisible(false)}
            values={billingValues}
          />
        )}
      </div>
    </Spin>
  )
}

export default withRouter(ShowRezervationItem);
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslate } from 'react-translate';
import MediaQuery from 'react-responsive';
import { isObject } from 'lodash';
import moment from 'moment';
import { Spin, Modal } from 'antd';
import {
  VehicleCard, Extras, ExtrasDisplay, DriverInfo,
  Payment, PaymentCheckbox, CheckBox, Button, Helmet,
  InfoModal, CorporateBillInfo,
} from '../../common/components';

import ReservationSteps from './Steps';
import ResevationSuccessBlock from './SuccessBlock';

import useReservation from './useReservation';

import styles from './Reservation.module.scss';

import VehicleExtraProcessIcon from '../../assets/vehicle-extra-process.svg';
import VehicleExtraFinishIcon from '../../assets/vehicle-extra-finish.svg';

import CustomerInfoWaitIcon from '../../assets/customer-info-wait.svg';
import CustomerInfoProcessIcon from '../../assets/customer-info-process.svg';
import CustomerInfoFinishIcon from '../../assets/customer-info-finish.svg';

import PaymentWaitIcon from '../../assets/payment-wait.svg';
import PaymentProcessIcon from '../../assets/payment-process.svg';
import PaymentFinishIcon from '../../assets/payment-finish.svg';

import ErrorIcon from '../../assets/error-icon.svg';


const Reservation = (props) => {
  const { history, match, location } = props;

  const t = useTranslate('vehicle');
  
  const {
    extras,
    vehicle,
    getSegment,
    loading,
    params,
    setFormData,
    formData,
    paymentResponse,
    submitReservation,
  } = useReservation({ match, location, history });

  const onChangeFormData = (key, e) => {
    setFormData({
      ...formData,
      [key]:{
        ...formData[key],
        [e.target.name]: e.target.value
      }
    });
  }


  const getExtrasDisplayComponent = () => {
    if(formData.selectedExtras.length) {
      return <ExtrasDisplay extras={formData.selectedExtras} />;
    }
    return null;
  }
  // const pickupTime = moment(params.pickup_time);
  // const dropoffTime = moment(params.dropoff_time);
  // const vehicleDropPickTimeDiff = dropoffTime.diff(pickupTime, 'days');

  const extrasPrice = formData.selectedExtras.reduce((total, item) => {
    const itemPrice = item.quantity * item.price;
    const calculatedByDaily = vehicle.payable_days * itemPrice;
    total += calculatedByDaily;
    return total;
  }, 0) || 0;

  const getVehicleComponent = () => (
    <VehicleCard
      item={vehicle || {}}
      segment={getSegment(vehicle.vehicle_segment_id)}
      state="reservation"
      prices={{
        vehicle: vehicle.price_readable,
        extras: extrasPrice,
        total: vehicle.price_readable + extrasPrice,
      }}
      params={{
        ...params,
        pickup_time_readable: {
          date: moment(params.pickup_time).format('DD.MM.YYYY'),
          time: moment(params.pickup_time).format('HH:mm'),
        },
        dropoff_time_readable: {
          date: moment(params.dropoff_time).format('DD.MM.YYYY'),
          time: moment(params.dropoff_time).format('HH:mm'),
        },
      }}
    />
  );

  const getDriverInfoComponent = (isReadOnly) => (
    <DriverInfo
      userInfo={formData.user}
      onChange={(e) => onChangeFormData('user', e)}
      readOnly={isReadOnly}
      {...(isReadOnly ? { errors: {} } : { errors: formData.userErrors })}
    />
  );

  const getPaymentCheckboxComponent = (noCheckbox) => (
    <PaymentCheckbox
      noCheckbox={noCheckbox}
      checked={formData.payByCard}
      method={paymentResponse.payment_method}
      openModal={() => setFormData({ ...formData, corporateBillInfoVisible: true })}
      onChange={(e) => {
        setFormData({
          ...formData,
          payOnDelivery: !e.target.checked,
          payByCard: e.target.checked
        })
      }}
    />
  );

  const reservationSteps = [{
    name: t('aracEkstraSecimi'),
    id: 0,
    icons: {
      process: VehicleExtraProcessIcon,
      finish: VehicleExtraFinishIcon,
    },
    content: (
      <>
        <MediaQuery maxWidth={991}>
          {getVehicleComponent()}
        </MediaQuery>
        <Extras
          setSelectedExtras={(data) => setFormData({ ...formData, selectedExtras: data })}
          selectedExtras={formData.selectedExtras}
          extras={extras.data || []}
        />
      </>
    ),
  }, {
    name: t('musteriBilgileri'),
    id: 1,
    icons: {
      wait: CustomerInfoWaitIcon,
      process: CustomerInfoProcessIcon,
      finish: CustomerInfoFinishIcon,
    },
    content: (
      <React.Fragment>
        <MediaQuery minWidth={991}>
          {getExtrasDisplayComponent()}
        </MediaQuery>
        {getDriverInfoComponent(false)}
      </React.Fragment>
    )
  }, {
    name: t('odemeIslemleri'),
    id: 2,
    icons: {
      wait: PaymentWaitIcon,
      process: PaymentProcessIcon,
      finish: PaymentFinishIcon,
    },
    content: (
      <React.Fragment>
        <MediaQuery minWidth={991}>
          {getExtrasDisplayComponent()}
          {getDriverInfoComponent(true)}
          {paymentResponse.id && getPaymentCheckboxComponent(true)}
        </MediaQuery>

        {!paymentResponse.id && (
          <div>
            {formData.payOnDelivery ?
              getPaymentCheckboxComponent()
            : (
              <Payment
                onChange={(e) => onChangeFormData('card', e)}
                payment={formData.card}
                errors={formData.cardErrors}
                openModal={() => setFormData({ ...formData, corporateBillInfoVisible: true })}
              />
            )}
          </div>
        )}
        {paymentResponse.id && (
          <MediaQuery maxWidth={991}>
            {getVehicleComponent()}
            {getExtrasDisplayComponent()}
            {getDriverInfoComponent(true)}
            {getPaymentCheckboxComponent(true)}
          </MediaQuery>
        )}
      </React.Fragment>
    )
  }];

  const pageTitles = [t('aracEkstraSecimi'), t('musteriBilgileri'), t('odemeBilgileri')];

  return (
    <Spin spinning={loading} delay={200}>
      <Helmet title={paymentResponse.id ? t('basariliOdemeBaslik') : pageTitles[formData.currentStep]} />
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <ReservationSteps
            reservationSteps={reservationSteps}
            stepStatuses={formData.stepStatuses}
          />
          {paymentResponse.id && (
            <ResevationSuccessBlock
              paymentResponse={paymentResponse}
              history={history}
              isPaidByCard={formData.payByCard}
            />
          )}
          <MediaQuery minWidth={991}>
            {getVehicleComponent()}
          </MediaQuery>

          <div>
            {reservationSteps[formData.currentStep].content}
          </div>
          {!paymentResponse.id && formData.currentStep === 2 && (
            <div className={styles.checkboxes}>
              <CheckBox
                rounded
                checked={formData.termsCond}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    termsCond: e.target.checked,
                  });
                  if(e.target.checked && formData.termsCondError) {
                    setFormData({
                      ...formData,
                      termsCond: e.target.checked,
                      termsCondError: false,
                    });
                  }
                }}
              >
                <a // eslint-disable-line
                  onClick={() => setFormData({ ...formData, rentTermsModalVisible: true })}
                >{t('kiralamaKosullarini')}</a> {t('okudumKabulEdiyorum')}
              </CheckBox>
              {formData.termsCondError && (
                <span className={styles.termsCondError}>{t('termCondError')}</span>
              )}
              <CheckBox
                rounded
                checked={formData.payOnDelivery}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    payOnDelivery: e.target.checked,
                    payByCard: !e.target.checked
                  })
                }}
              >
                {t('teslimdeOde')}
              </CheckBox>
            </div>
          )}  
          {formData.rentError && isObject(formData.rentError) &&  Object.keys(formData.rentError).map(err => (
            <div className={styles.errorBlock} key={err}>{formData.rentError[err]}</div>
          ))}
          {!paymentResponse.id && (
            <Button
              round
              type="primary"
              className={styles.submitButton}
              onClick={() => submitReservation()}
            >
              {formData.currentStep === 2 ? (
                formData.payOnDelivery ? t('talepGonder') : t('tamamla')
              ) : (
                t('devamEt')
              )}
            </Button>
          )}
        </div>
      </div>
      {formData.rentTermsModalVisible && (
        <InfoModal
          dataName="rental-agreement"
          title={t('kiralamaKosullarini')}
          onClose={() => setFormData({ ...formData, rentTermsModalVisible: false })}
        />
      )}

      {formData.html3D && (
        <div className="paymentFormWrapper">
          <div style={{ height: 1 }} dangerouslySetInnerHTML={{ __html: formData.html3D }} />
        </div>
      )}
      {formData.corporateBillInfoVisible && (
        <CorporateBillInfo
          values={formData.billing}
          onClose={() => setFormData({ ...formData, corporateBillInfoVisible: false })}
          submit={(billing) => {
            setFormData({
              ...formData,
              corporateBillInfoVisible: false,
              billing
            });
          }}
        />
      )}
      {formData.checkoutErrorVisible && (
        <Modal
          visible
          onCancel={() => setFormData({ ...formData, checkoutErrorVisible: false })}
          width={445}
          footer={null}
          className={styles.checkoutErrorModal}
        >
          <img src={ErrorIcon} alt="" />
          <h1> {t('checkoutErrorText')}</h1>
          <p>{formData.checkoutErrorText}</p>
        </Modal>
      )}
    </Spin>
  );
};

export default withRouter(Reservation);

import React from 'react';
import { useTranslate } from 'react-translate';

import AutoIcon from '../../../assets/auto.svg';
import SuitcaseIcon from '../../../assets/suitcase.svg';
import CarDoorIcon from '../../../assets/car-door.svg';
import CarSeatIcon from '../../../assets/car-seat.svg';
import FuelIcon from '../../../assets/fuel.svg';

const VehicleFeatures = ({ properties = {}, className }) => {
  const t = useTranslate('vehicle');

  return (
    <ul className={className}>
      <li>
        <img src={AutoIcon} alt="Otomatik" />
        <span>{t(properties.transmission)}</span>
      </li>
      <li>
        <img src={SuitcaseIcon} alt="valiz" />
        <span>{properties.luggage_size} {t('valiz')}</span>
      </li>
      <li>
        <img src={CarDoorIcon} alt="kapi" />
        <span>{properties.door_count} {t('kapi')}</span>
      </li>
      <li>
        <img src={CarSeatIcon} alt="koltuk" />
        <span>{properties.seat_count} {t('koltuk')}</span>
      </li>
      <li>
        <img src={FuelIcon} alt="benzin" />
        <span>{t(properties.fuel_type)}</span>
      </li>
    </ul>
  );
};

export default VehicleFeatures;

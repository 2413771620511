import React from 'react';
import cx from 'classnames';
import MediaQuery from 'react-responsive';
import { useTranslate } from 'react-translate';

import { Button, CheckBox, SearchInList } from '../';
import ReturnDateAndTime from './ReturnDateAndTime';
import ReceivingDateAndTime from './ReceivingDateAndTime';
import useSearchForm from './useSearchForm';

import styles from './SearchForm.module.scss';

import ReceivingPlaceIcon from '../../../assets/receiving-place-icon.svg';

const SearchForm = ({ onChange, values }) => {
  const t = useTranslate('common');
  const {
    submitData,

    handleReceivingPlaceChange,
    handleReturnPlaceChange,
    handleReceivingTimeChange,
    handleReceivingDateChange,
    handleReturnTimeChange,
    handleReturnDateChange,

    setReceivingTimeVisible,
    setFocusedInput,
    setReceivingDate,
    setFocusedSingleDateInput,
    setReturnDate,
    setDeliverFromDifferentPlace,
    setReceivingPlaceVisible,
    setReturnPlaceVisible,
    setReturnTimeVisible,

    returnDate,
    returnTime,
    receivingTimeVisible,
    receivingTime,
    receivingDate,
    focusedSingleDateInput,
    errors,
    focusedInput,
    returnTimeVisible,
    returnPlaceVisible,
    receivingPlace,
    mappedRegions,
    returnPlace,
    receivingPlaceVisible,
    deliverFromDifferentPlace,
  } = useSearchForm(onChange, values);

  const deliverDifferentPlaceCheckbox = (
    <CheckBox
      onChange={(e) => setDeliverFromDifferentPlace(e.target.checked)}
      checked={deliverFromDifferentPlace}
      className={styles.deliverCheckbox}
    >
      {t('farkliNoktaTeslimi')}
    </CheckBox>
  );


  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <div className={cx(styles.selectWrapper, styles.field, styles.firstField)}>
          <span className={styles.label}>{deliverFromDifferentPlace ? t('alisYeri') : t('alisDonusYeri')}</span>
          <div className={cx(styles.fakeInput, { [styles.active]: !!receivingPlace.name, [styles.errorField]: errors.receivingPlace })}>
            <button onClick={() => setReceivingPlaceVisible(true)}>
              <img src={ReceivingPlaceIcon} alt="" />
              <span>{receivingPlace.name || t('alisYeri')}</span>
            </button>
            {errors.receivingPlace && (
              <div className={styles.error}>{t('gerekliAlan')}</div>
            )}
            { receivingPlaceVisible && (
              <SearchInList
                list={mappedRegions}
                defaultValue={receivingPlace}
                visible={receivingPlaceVisible}
                setVisible={setReceivingPlaceVisible}
                setData={handleReceivingPlaceChange}
              />
            )}
          </div>
          <MediaQuery maxWidth={991}>
            {deliverDifferentPlaceCheckbox}
            {deliverFromDifferentPlace && (
              <div style={{marginTop : "5px"}}>
              <small>{t('farkliNoktaTeslimiNot')}</small>
        </div>
      )}
          </MediaQuery>
        </div>
        {deliverFromDifferentPlace && (
          <div className={cx(styles.selectWrapper, styles.field)}>
            <span className={styles.label}>{t('donusYeri')}</span>
            <div className={cx(styles.fakeInput, styles.place, {[styles.active]: !!returnPlace.name, [styles.errorField]: errors.returnPlace })}>
              <button onClick={() => {
                setReturnPlaceVisible(true)
              }}>
                <img src={ReceivingPlaceIcon} alt="" style={{ transform: 'rotate(90deg)' }} />
                <span>{returnPlace.name || t('donusYeri')}</span>
              </button>
              {errors.returnPlace && (
                <div className={styles.error}>{t('gerekliAlan')}</div>
              )}
              {returnPlaceVisible && (
                <SearchInList
                  list={mappedRegions}
                  defaultValue={returnPlace}
                  visible={returnPlaceVisible}
                  setVisible={setReturnPlaceVisible}
                  setData={handleReturnPlaceChange}
                />
              )}
            </div>
          </div>
        )}

        <ReceivingDateAndTime
          setReceivingTimeVisible={setReceivingTimeVisible}
          receivingTimeVisible={receivingTimeVisible}
          receivingTime={receivingTime}
          receivingDate={receivingDate}
          focusedSingleDateInput={focusedSingleDateInput}
          setFocusedInput={setFocusedInput}
          setReceivingDate={setReceivingDate}
          setFocusedSingleDateInput={setFocusedSingleDateInput}
          handleReceivingTimeChange={handleReceivingTimeChange}
          handleReceivingDateChange={handleReceivingDateChange}
          setReturnDate={setReturnDate}
          errors={errors}
        />
        
        <ReturnDateAndTime
          returnTimeVisible={returnTimeVisible}
          setReturnTimeVisible={setReturnTimeVisible}
          receivingDate={receivingDate}
          returnDate={returnDate}
          returnTime={returnTime}
          focusedInput={focusedInput}
          setReturnDate={setReturnDate}
          setReceivingDate={setReceivingDate}
          setFocusedInput={setFocusedInput}
          handleReturnDateChange={handleReturnDateChange}
          handleReturnTimeChange={handleReturnTimeChange}
          setReceivingTimeVisible={setReceivingTimeVisible}
          errors={errors}
        />
        
        <Button
          className={styles.submitButton}
          icon="search"
          size="large"
          onClick={() => {
            submitData();
          }}
        >
          {t('ara')}
        </Button>
      </div>
      <MediaQuery minWidth={991}>
        {deliverDifferentPlaceCheckbox}
            {deliverFromDifferentPlace && (
              <div style={{marginTop : "5px"}}>
                <small>{t('farkliNoktaTeslimiNot')}</small>
              </div>
      )}
      </MediaQuery>
      
    </div>
  );
};

SearchForm.defaultProps = {
  values: {},
};

export default SearchForm;

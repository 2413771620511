import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useTranslate } from 'react-translate';
import useOnClickOutside from '../../../utils/useOnClickOutside';
import placeIconTypes from '../../../utils/placeIconTypes';

import styles from './SearchInList.module.scss';

// import ReceivingPlaceIcon from '../../../assets/receiving-place-icon.svg';
import SearchIcon from '../../../assets/search.svg';

function _fnEscapeRegex(sVal) {
  if(sVal == null || sVal.length === 0){
    return;
  }
  const letters = { "İ": "i" };

  return sVal.replace(/(([İ]))/g, function (letter) { return letters[letter]; });
}
const SearchInList = ({ setVisible, visible, list, type, setData, searchVisible, imgStyle }) => {
  const t = useTranslate('common');
  const rootRef = useRef();
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState('');

  const filteredList = list.filter((dt) => {
    if(Object.entries(dt).length === 0 && dt.constructor === Object){
      return false;
    }
    return _fnEscapeRegex(dt.name).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  })

  const init = () => {
    if(searchVisible) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    init();
  });

  useOnClickOutside(rootRef, () => setVisible(false));

  return (
    <div
      className={cx(styles.root, {
        [styles.visible]: visible,
      })}
      ref={rootRef}
    >
      {searchVisible && (
        <div className={styles.field}>
          <img src={SearchIcon} alt="" />
          <input
            ref={inputRef}
            value={inputValue}
            placeholder={t('ara')}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
      )}
      <div className={styles.list}>
        {!filteredList.length && (
          <div className={styles.noData}>{t('aramaSonucYok')}</div>
        )}
        <ul>
          {filteredList.map((dt) => (
            <li key={dt.id}>
              <button
                onClick={() => {
                  setInputValue(dt.name);
                  setData(dt);
                  setVisible(false);
                }}
              >
                {placeIconTypes[dt.type] && <img src={placeIconTypes[dt.type]} alt="" />}
                <span>{dt.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

SearchInList.defaultProps = {
  searchVisible: true,
};

export default SearchInList;

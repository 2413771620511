import React from 'react';
import cx from 'classnames';
import { Checkbox } from 'antd';
import styles from './CheckBox.module.scss';

const CheckBoxWrapper = (props) => {
  const { onChange, checked, children, className, rounded, name } = props;

  return (
    <Checkbox
      name={name}
      checked={checked}
      onChange={onChange}
      className={cx(styles.root, className, { [styles.rounded]: rounded })}
    >
      {children}
    </Checkbox>
  );
};

export default CheckBoxWrapper;

import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import cx from 'classnames';

import { Modal, Button, ErrorBlock } from '../';

import { validateBillingForm } from '../../../containers/Reservation/utils'

import styles from './Payment.module.scss';

const CorporateBillInfo = ({ onClose, values, submit, readOnly }) => {
  const t = useTranslate('vehicle');

  const [corporateBillInfo, setCorporateBillInfo] = useState({
    title: '',
    token: '',
    office: '',
    address: '',
    ...values
  });
  const [errors, setErrors] = useState({});

  const onChange = (e) => {
    setCorporateBillInfo({
      ...corporateBillInfo,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Modal
      title={t('kurumsalFaturaBilgileri')}
      visible
      onClose={onClose}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.formItemWrapper}>
          <div className={cx('formItem', {[styles.error]: errors.title })}>
            <label>{t('sirketUnvaniAdSoyad')}</label>
            <input
              placeholder={t('sirketUnvaniAdSoyad')}
              name="title"
              onChange={onChange}
              disabled={readOnly}
              value={corporateBillInfo.title}
            />
            <ErrorBlock show={errors.title} value={corporateBillInfo.title} />
          </div>
          <div className={cx('formItem', {[styles.error]: errors.office })}>
            <label>{t('vergiDairesi')}</label>
            <input
              placeholder={t('vergiDairesi')}
              name="office"
              onChange={onChange}
              disabled={readOnly}
              value={corporateBillInfo.office}
            />
            <ErrorBlock show={errors.office} value={corporateBillInfo.office} />
          </div>
        </div>
        <div className={styles.formItemWrapper}>
          <div className={cx('formItem', {[styles.error]: errors.token })}>
            <label>{t('vergiNumarasiTcNo')}</label>
            <input
              placeholder={t('vergiNumarasiTcNo')}
              name="token"
              pattern="[0-9]*"
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  return onChange(e);
                }
              }}
              disabled={readOnly}
              value={corporateBillInfo.token}
            />
            <ErrorBlock show={errors.token} value={corporateBillInfo.token} />
          </div>
          <div className={cx('formItem', {[styles.error]: errors.address })}>
            <label>{t('address')}</label>
            <textarea
              placeholder={t('address')}
              name="address"
              onChange={onChange}
              disabled={readOnly}
              value={corporateBillInfo.address}
            ></textarea>
            <ErrorBlock show={errors.address} value={corporateBillInfo.address} />
          </div>
        </div>
        {!readOnly && (
          <Button
            round
            type="primary"
            onClick={() => {
              if(!validateBillingForm(corporateBillInfo, setErrors, errors)) {
                return false;
              }
              submit(corporateBillInfo);
            }}
          >
            {t('guncelle')}
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default CorporateBillInfo;
import moment from 'moment';

const getTimes = () => {
  const items = []
  var currentDate = moment('0000', 'hmm');
  new Array(48).fill().map((acc, index) => {
    items.push(currentDate.format('HH:mm'))
    currentDate = currentDate.add(30, 'minutes');
  })
  return items
};


export {
  getTimes,
};

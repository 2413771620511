import { useState, useEffect } from 'react';
import { fetchCampaigns } from './services';
import useApp from '../App/AppContext';

const useCampaigns = () => {
  const { lang } = useApp();
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState({ data: [] });

  const getCampaigns = async () => {
    const res = await fetchCampaigns();
    setCampaigns(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const getKeyByLang = (item, key) => {
    if(item && item[key] && item[key][lang]) {
      return item[key][lang];
    }
    return null;
  };

  return {
    getKeyByLang,
    loading,
    campaigns,
  }
}

export default useCampaigns;
import request from '../../utils/request';

const searchVehicle = async (options = {}) => request('vehicle/search', { method: 'POST', ...options});
const fetchVehicleSegments = async (options = {}) => request('vehicle/segment', { ...options});
const fetchSingleVehicle = async (id, options = {}) => request(`vehicle?id=${id}`, { ...options});
const fetchVehicleExtras = async (options = {}) => request(`vehicle/extra`, { ...options});
const fetchVehicles = async (options = {}) => request(`vehicle?limit=999&page=1`, { ...options});

export {
  searchVehicle,
  fetchVehicles,
  fetchSingleVehicle,
  fetchVehicleSegments,
  fetchVehicleExtras,
}


import React, { useState } from 'react';
import cx from 'classnames';
import { Spin } from 'antd';
import { useTranslate } from 'react-translate';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

import { PhoneNumber, Button, SuccessModal, Helmet } from '../../common/components';
import { validateEmail } from '../../utils';
import request from '../../utils/request';

import styles from './Contact.module.scss';

import BANNER from '../../assets/contact-banner.jpg';

const validateContact = (values, setErrors, errors) => {
  const feildErrors = {};

  const hasErros = Object.keys(values).map((field) => {
    if(!values[field]) {
      feildErrors[field] = true;
      return true;
    }

    if(field === 'email' && !validateEmail(values[field])) {
      feildErrors[field] = true;
      return true;
    }

    if(field === 'mobile' && !isValidPhoneNumber(values[field])) {
      feildErrors[field] = true;
      return true;
    }

    feildErrors[field] = false;
    return false;
  }).filter(field => field);

  setErrors({
    ...errors,
    ...feildErrors,
  });

  return !hasErros.length;
}


const Contact = () => {
  const [values, setValues] = useState({
    subject: '',
    email: '',
    mobile: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const t = useTranslate('contact');

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submit = async () => {
    if(!validateContact(values, setErrors, errors)) {
      return false
    }
    setLoading(true);
    const mobile = parsePhoneNumber(values.mobile || '');
    const res = await request('contact', {
      method: 'POST',
      data: {
        ...values,
        mobile: `${mobile.countryCallingCode}${mobile.nationalNumber}`
      },
    });
    setLoading(false);
    if(res.errors) {
      return;
    }

    setValues({
      subject: '',
      email: '',
      mobile: '',
      message: '',
    });
    setModalVisible(true);
  };

  return (
    <Spin spinning={loading} delay={200}>
      <Helmet title={t('iletisim')} />
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <h2>{t('iletisim')}</h2>
          <div className={styles.content}>
            <div className={styles.form}>
              <div className={cx('formItem', {[styles.error]: errors.subject })}>
                <label>{t('konu')}</label>
                <input
                  placeholder="Konu"
                  onChange={onChange}
                  name="subject"
                  value={values.subject}
                />
                <div className={styles.errorText}>{t('gerekliAlan')}</div>
              </div>
              <div className={cx('formItem', {[styles.error]: errors.email })}>
                <label>{t('eposta')}</label>
                <input
                  type="email"
                  placeholder={t('eposta')}
                  onChange={onChange}
                  name="email"
                  value={values.email}
                />
                <div className={styles.errorText}>{t('gerekliAlan')}</div>
              </div>
              <div className={cx('formItem', {[styles.error]: errors.mobile })}>
                <label>{t('GSM')}</label>
                <PhoneNumber
                  onChange={(value) => setValues({ ...values, mobile: value })}
                  name="mobile"
                  value={values.mobile}
                />
                <div className={styles.errorText}>{t('gerekliAlan')}</div>
              </div>
              <div className={cx('formItem', {[styles.error]: errors.message })}>
                <label>{t('mesaj')}</label>
                <textarea
                  placeholder={t('mesaj')}
                  onChange={onChange}
                  name="message"
                  value={values.message}
                />
                <div className={styles.errorText}>{t('gerekliAlan')}</div>
              </div>
              <div className="formItem">
                <Button
                  round
                  type="primary"
                  onClick={submit}
                >
                  {t('gonder')}
                </Button>
              </div>
            </div>
            <div className={styles.banner}>
              <img src={BANNER} alt="" />
            </div>
          </div>
        </div>
        {modalVisible && (
          <SuccessModal
            text={t('basariliMesaj')}
            onClose={() => setModalVisible(false)}
          />
        )}
      </div>
    </Spin>
  );
};

export {
  validateContact,
}
export default Contact;

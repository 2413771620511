import React from 'react';
import { useTranslate } from 'react-translate';
import cx from 'classnames';
import { withRouter} from 'react-router-dom';

import { Button } from '../';

import styles from './VehicleCard.module.scss';

const VehicleReservation = ({ price_readable, price_readable_before_campaign, discount_applied, history, id }) => {
  const t = useTranslate('vehicle');

  return (
    <div className={cx(styles.reservation, { [styles.discount]: discount_applied })}>
      <div className={cx(styles.specialPrice)}>
        Özel Fiyat
      </div>
      <div>
        <h2>{price_readable},<span>00</span> <strong>₺</strong></h2>
        <h3>{price_readable_before_campaign},00<span></span> <strong>₺</strong></h3>
      </div>
      <Button
        type="primary"
        round
        onClick={() => {
          history.push(`/reservation/${id}${history.location.search}`);
        }}
      >
        {t('rezervasyonYap')}
      </Button>
    </div>
  );
}

export default withRouter(VehicleReservation);

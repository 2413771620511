import moment from 'moment';
import { isObject } from 'lodash';

const getLanguage = () => localStorage.getItem('lang') || 'tr';
const getToken = () => localStorage.getItem('token');

const getSearchParamsValues = (values) => {
  const params = {
    receivingTime: {
      id: moment(values.pickup_time).format('HH:mm'),
      name: moment(values.pickup_time).format('HH:mm'),
    },
    receivingDate: moment(values.pickup_time).format(),
    returnTime: {
      id: moment(values.dropoff_time).format('HH:mm'),
      name: moment(values.dropoff_time).format('HH:mm'),
    },
    returnDate: moment(values.dropoff_time).format(),
    receivingPlace: values.pickup_place,
    ...(values.dropoff_place ? { returnPlace: values.dropoff_place } : {})
  };

  return params;
}

const getSearchParamsQuery = (values) => {
  const date = moment().format('YYYY-MM-DD');
  const receivingDate = moment(values.receivingDate).format('YYYY-MM-DD');
  const returnDate = moment(values.returnDate).format('YYYY-MM-DD');
  const receivingTime = moment(`${date} ${values.receivingTime.id}`).format('HH:mm');
  const returnTime = moment(`${date} ${values.returnTime.id}`).format('HH:mm');

  const params = {
    pickup_time: `${receivingDate} ${receivingTime}`,
    dropoff_time: `${returnDate} ${returnTime}`,
    pickup_place: values.receivingPlace.id,
    ...(values.returnPlace ? { dropoff_place: values.returnPlace.id } : {})
  };

  return params;
}

const getName = (item) => {
  const lang = getLanguage();
  if(!item && !isObject(item)) return null;

  if(item.name && item.name[lang]) {
    return item.name[lang];
  }

  return item.name_readable;
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const getKeyByLang = (item, key) => {
  const lang = getLanguage();
  if(item && item[key] && item[key][lang]) {
    return item[key][lang];
  }
  return null;
};

export {
  getName,
  getToken,
  getLanguage,
  getKeyByLang,
  validateEmail,
  getSearchParamsQuery,
  getSearchParamsValues,
}
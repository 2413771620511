import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Spin } from 'antd';
import { parsePhoneNumber } from 'react-phone-number-input'

import { DriverInfo, Button, SuccessModal, InfoModal } from '../../common/components';
import appUse from '../App/AppContext';
import { validateUserForm } from '../Reservation/utils';
import { updateMe } from './services';

import styles from './Account.module.scss';

const Details = ({ history }) => {
  const t = useTranslate('account');
  const tCm = useTranslate('login');
  const { me, setMe } = appUse();

  const [termsConditionsVisible, setTermsConditionsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    user: {
      name: me.name || '',
      email: me.email || '',
      surname: me.surname || '',
      mobile: me.mobile ? `+${me.mobile}` : ''
    },
    userErrors: {},
  })

  const submitForm = async () => {
    if(!validateUserForm(formData, setFormData)) {
      return false;
    }
    setLoading(true);
    const phone = parsePhoneNumber(formData.user.mobile || '');
    const res = await updateMe({
      data: {
        ...formData.user,
        mobile: `${phone.countryCallingCode}${phone.nationalNumber}`,
      }
    });

    if(res.errors) {
      const errs = {};
      Object.keys(res.errors).map(err => {
        return errs[err] = res.errors[err][0];
      });
      setFormData({ ...formData, userErrors: { ...errs } })
      return setLoading(false);
    }
    setMe(res.data.data);
    localStorage.setItem('me', JSON.stringify(res.data.data));
    setLoading(false);
    setSuccess(true);
  }

  const KVKLink = (
    <button 
      onClick={() => setTermsConditionsVisible(true)}
      className={styles.kvkLink}
    >
      {t('KVKKosullari')}
    </button>
  );
  return (
    <Spin spinning={loading} delay={200} className={styles.detailsWrapper}>
      {success && (
        <SuccessModal
          onClose={() => setSuccess(false)}
          text={t('successModalText')}
        />
      )}
      <div className={styles.details}>
        <div className={styles.left}>
          <DriverInfo
            noTitle
            onChange={(e) => {
              setFormData({
                ...formData,
                user: {
                  ...formData.user,
                [e.target.name]: e.target.value
                }
              });
            }}
            userInfo={formData.user}
            errors={formData.userErrors}
          />
        </div>
        <MediaQuery maxWidth={991}>
          {KVKLink}
        </MediaQuery>
        <div className={styles.right}>
          <Button
            type="primary"
            round
            onClick={submitForm}
          >
            {t('kaydet')}
          </Button>
        </div>
      </div>
      <MediaQuery minWidth={991}>
        {KVKLink}
      </MediaQuery>
      {termsConditionsVisible && (
        <InfoModal
          title={tCm('kisiselVeri')}
          dataName="law-of-personal-data"
          onClose={() => setTermsConditionsVisible(false)}
        />
      )}
      <button
        className={styles.logout}
        onClick={() => {
          setMe({});
          localStorage.removeItem('me');
          localStorage.removeItem('token');
          history.push('/');
        }}
      >
        {tCm('cikisYap')}
      </button>
    </Spin>
  );
};

export default withRouter(Details);

import React from 'react';
import cx from 'classnames';
import { Button } from 'antd';
import styles from './Button.module.scss';

const ButtonWrapper = (props) => {
  const {
    type,
    size,
    icon,
    round,
    onClick,
    children,
    className,
  } = props;

  const classNames = cx(styles.button, className, {
    [styles.primary]: type === 'primary',
    [styles.round]: round,
  });

	return (
    <Button
      size={size}
      icon={icon}
      type={type}
      className={classNames}
      onClick={onClick}
    >
      {children}
    </Button>
	);
};

export default ButtonWrapper;

import React from 'react';
import { useTranslate } from 'react-translate';
import MediaQuery from 'react-responsive';
import NumberFormat from 'react-number-format';
import cx from 'classnames';

import useApp from '../../../containers/App/AppContext';

import styles from './VehicleCard.module.scss';
import Photos from './Photos';
import VehicleFeatures from './VehicleFeatures';
import VehicleReservation from './VehicleReservation';

import { getName } from '../../../utils';
import placeIconTypes from '../../../utils/placeIconTypes';

import InfoIcon from '../../../assets/info.svg';
import TimeIcon from '../../../assets/time-icon.svg'
import ReceivingDateIcon from '../../../assets/receiving-date-icon.svg'
import ReturnDateIcon from '../../../assets/return-date-icon.svg'

const VehicleCard = ({ item, segment, state, params, prices = {} }) => {
  const { name = {}, properties = {}, images_readable = []} = item;
  const t = useTranslate('vehicle');
  const { lang, regions, settings } = useApp();

  const dropoffPlace = regions.data.find(region => region.id === Number(params.dropoff_place)) || {};
  const pickupPlace = regions.data.find(region => region.id === Number(params.pickup_place)) || {};

  const vehicleName = item.name_readable || name[lang];
  const images = images_readable;
  const isReservation = state === 'reservation';

  const kmPrice = settings.data && settings.data.km_price;

  return (
    <div className={styles.root}>
      <MediaQuery maxWidth={991}>
        <h1 className={styles.title}>{vehicleName} <span>{t('veyaBenzeri')}</span></h1>
      </MediaQuery>
      <div className={styles.top}>
        <Photos photos={images} />
        <div className={styles.vehicleDetails}>
          <MediaQuery minWidth={991}>
            <h1 className={styles.title}>{vehicleName} <span>{t('veyaBenzeri')}</span></h1>
          </MediaQuery>
          <VehicleFeatures properties={properties} />
          <div className={styles.tag}>{getName(segment)}</div>
          <div className={styles.price}>{t('gunluk')}: <span>{item.daily_price_readable},</span>00 ₺</div>
        </div>
        <MediaQuery minWidth={991}>
          {isReservation ? (
            <div className={styles.listPrice}>
              <ul>
                {!!prices.vehicle && (
                  <li>
                    <span>{t('fiyat')}:</span>
                    <strong>
                      <NumberFormat
                        decimalSeparator=","
                        thousandSeparator="."
                        value={prices.vehicle}
                        displayType={'text'}
                        renderText={value => value}
                      />,00
                      <span>₺</span>
                    </strong>
                  </li>
                )}
                {!!prices.extras && (
                  <li>
                    <span>{t('ekstralar')}:</span>
                    <strong>
                      <NumberFormat
                        decimalSeparator=","
                        thousandSeparator="."
                        value={prices.extras}
                        displayType={'text'}
                        renderText={value => value}
                      />,00
                      <span>₺</span>
                    </strong>
                  </li>
                )}
                {!!prices.total && (
                  <li>
                    <span>{t('toplam')}:</span>
                    <strong>
                      <NumberFormat
                        decimalSeparator=","
                        thousandSeparator="."
                        value={prices.total}
                        displayType={'text'}
                        renderText={value => value}
                      />,00
                      <span>₺</span>
                    </strong>
                  </li>
                )}
              </ul>
            </div>
          ) : (
            <VehicleReservation {...item} />
          )}
        </MediaQuery>
      </div>
      <div className={styles.bottom}>
        <div className={cx(styles.box, {[styles.reservationBox]: isReservation })}>
          <h3>{t('teslimAlis')}</h3>
          <ul>
            <li className={styles.boxLi}>
              <div className={styles.itemInItem}>
                {placeIconTypes[pickupPlace.type] && <img src={placeIconTypes[pickupPlace.type]} alt="" />} {getName(pickupPlace)}
              </div>
              {params.pickup_time_readable && (
                <div className={styles.itemInItemWrapper}>
                  <div className={styles.itemInItem}>
                    <img src={ReceivingDateIcon} alt="" /> {params.pickup_time_readable.date}
                  </div>
                  <div className={styles.itemInItem}>
                    <img src={TimeIcon} alt="" /> {params.pickup_time_readable.time}
                  </div>
                </div>
              )}
            </li>
            <li className={styles.boxLi}>
              <div className={styles.itemInItem}>
                {placeIconTypes[dropoffPlace.type] && <img src={placeIconTypes[dropoffPlace.type]} alt="" />}
                {!placeIconTypes[dropoffPlace.type] && placeIconTypes[pickupPlace.type] && <img src={placeIconTypes[pickupPlace.type]} alt="" />}
                {' '} {getName(dropoffPlace) || getName(pickupPlace)}
              </div>
              {params.dropoff_time_readable && (
                <div className={styles.itemInItemWrapper}>
                  <div className={styles.itemInItem}>
                    <img src={ReturnDateIcon} alt="" /> {params.dropoff_time_readable.date}
                  </div>
                  <div className={styles.itemInItem}>
                    <img src={TimeIcon} alt="" /> {params.dropoff_time_readable.time}
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
        <div className={cx(styles.box, {[styles.reservationBox]: isReservation })}>
          <h3><img src={InfoIcon} alt="info" />{t('aracKMBilgisi')}</h3>
          <ul>
            <li>{t('kmBasina')} {kmPrice} TL</li>
          </ul>
        </div>
        <div className={cx(styles.box, {[styles.reservationBox]: isReservation })}>
          <h3><img src={InfoIcon} alt="info" />{t('aracKMHakki')}</h3>
          <ul>
            <li>{item.daily_free_km} {t('kmUcretsiz')}<span>{t('kmLimit')}</span></li>
          </ul>
        </div>
      </div>
      <MediaQuery maxWidth={991}>
        {isReservation ? (
          <div className={styles.mobilePrices}>
            <ul>
              {!!prices.extras && (
                <li>
                  <span>{t('ekstralar')}: </span>
                  <strong>
                    <NumberFormat
                      decimalSeparator=","
                      thousandSeparator="."
                      value={prices.extras}
                      displayType={'text'}
                      renderText={value => value}
                    />
                    <span>₺</span>
                  </strong>
                </li>
              )}
            </ul>
            <div className={styles.total}>
              <span>{t('toplam')}:</span>
              <strong>
                <NumberFormat
                  decimalSeparator=","
                  thousandSeparator="."
                  value={prices.total}
                  displayType={'text'}
                  renderText={value => value}
                /><span>,00</span>
                <span>₺</span>
              </strong>
            </div>
          </div> 
        ) : (
          <VehicleReservation
            {...item}
          />
        )}
      </MediaQuery>
    </div>
  );
};

export default VehicleCard;

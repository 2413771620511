import { useState, useEffect } from "react";
import queryString from "query-string";
import { omit, isObject } from "lodash";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { parsePhoneNumber } from "react-phone-number-input";

import appUse from "../App/AppContext";
import { validateUserForm, validatePaymentForm } from "./utils";
import { fetchVehicleExtras, searchVehicle } from "../Vehicles/services";
import { getSearchParamsValues, getSearchParamsQuery } from "../../utils";
import { rentVehicle, fetchRentedVehicle } from "./services";

const useReservation = ({ location, match, history } = {}) => {
  const { segments, me = {} } = appUse();
  const params = queryString.parse(location.search);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const [loading, setLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});
  const [extras, setExtras] = useState({});
  const [paymentResponse, setPaymentResponse] = useState({});
  const [formData, setFormData] = useState({
    user: {
      name: me.name || "",
      mobile: me.mobile ? `+${me.mobile}` : "",
      surname: me.surname || "",
      email: me.email || ""
    },
    billing: {
      title: "",
      token: "",
      office: "",
      address: ""
    },
    card: {
      // holdername: 'Şenol Örencik',
      holdername: "",
      // number: '5890040000000016',
      number: "",
      // cvc: '000',
      cvc: "",
      // expireDate: '12/20',
      expireDate: ""
    },
    stepStatuses: {
      0: "process",
      1: "wait",
      2: "wait"
    },
    selectedExtras: [],
    currentStep: 0,

    termsCond: false,
    termsCondError: false,
    corporateBillInfoVisible: false,
    rentError: false,
    payOnDelivery: false,
    payByCard: true,
    rentTermsModalVisible: false,

    html3D: "",

    userErrors: {},
    cardErrors: {},
    checkoutErrorVisible: false,
    checkoutErrorText: ""
  });

  const replaceUrl = () => {
    const newParams = omit(params, ["message", "status_code", "rent_id"]);
    history.replace(
      `/reservation/${match.params.vehicleId}?${queryString.stringify(
        newParams
      )}`
    );
  };

  const increaseStep = () => {
    const newStep = formData.currentStep + 1;
    setFormData({
      ...formData,
      currentStep: newStep,
      stepStatuses: {
        ...formData.stepStatuses,
        [newStep - 1]: "finish",
        [newStep]: "process"
      }
    });
  };

  const getSegment = (id) =>
    segments.data.find((segment) => segment.id === id) || {};

  const getVehicle = async () => {
    const values = getSearchParamsValues(queryString.parse(location.search));
    const params = getSearchParamsQuery(values);
    const res = await searchVehicle({
      params: {
        include: "segment,campaigns",
        id: match.params.vehicleId
      },
      data: {
        dropoff_time: params.dropoff_time,
        pickup_time: params.pickup_time
      }
    });

    if (res.errors) {
      history.push("/");
      return setLoading(false);
    }

    setVehicle(res.data.data[0]);
    setLoading(false);
  };

  const getVehicleExtras = async () => {
    const res = await fetchVehicleExtras();
    setExtras(res.data);
  };

  const getRentedVehicle = async () => {
    if (params.rent_id && params.status_code && params.status_code !== 402) {
      setLoading(true);
      const res = await fetchRentedVehicle(params.rent_id, {
        params: {
          include: "vehicle.segment,driver,user,invoice"
        }
      });
      if (res.errors) {
        setLoading(false);
        return setFormData({ ...formData, rentError: true });
      }
      setFormData({
        ...formData,
        user: {
          ...res.data.data.driver,
          mobile: `+${res.data.data.driver.mobile}`
        },
        currentStep: 2,
        stepStatuses: {
          0: "finish",
          1: "finish",
          2: "finish"
        },
        selectedExtras: res.data.data.extras.map((extra) => ({
          ...extra,
          ...extra.extra
        })),
        payOnDelivery: false,
        payByCard: true,
        ...(res.data.data.invoice.is_corporate === 1 && {
          billing: {
            title: res.data.data.invoice.billing_title,
            token: res.data.data.invoice.billing_token,
            office: res.data.data.invoice.billing_office,
            address: res.data.data.invoice.billing_address
          }
        })
      });
      setPaymentResponse(res.data.data);
      window.scrollTo(0, 0);
      setLoading(false);
      replaceUrl();
      localStorage.removeItem("checkoutData");
    } else if (Number(params.status_code) === 402) {
      const checkoutDataStorage = localStorage.getItem("checkoutData");
      const checkoutData = JSON.parse(checkoutDataStorage);

      setFormData({
        ...formData,
        user: {
          ...checkoutData.user,
          mobile: `+${checkoutData.user.mobile}`
        },
        currentStep: 2,
        stepStatuses: {
          0: "finish",
          1: "finish",
          2: "finish"
        },
        card: {
          holdername: checkoutData.card.holdername,
          number: checkoutData.card.number,
          cvc: checkoutData.card.cvc,
          expireDate: checkoutData.card.expireDate
        },
        selectedExtras: checkoutData.extras || [],
        payByCard: true,
        ...(checkoutData.isCorporate ? { billing: checkoutData.billing } : {}),
        checkoutErrorVisible: true,
        checkoutErrorText: params.message
      });
      replaceUrl();
      window.scrollTo(0, 0);
      localStorage.removeItem("checkoutData");
    }
  };

  useEffect(() => {
    getVehicle();
    getVehicleExtras();
  }, [match.params.vehicleId]); // eslint-disable-line

  useEffect(() => {
    getRentedVehicle();
  }, []); // eslint-disable-line

  const submitReservation = async () => {
    if (isTabletOrMobile) {
      window.scrollTo(0, 0);
    }
    if (formData.currentStep === 0) {
      increaseStep();
    }

    if (formData.currentStep === 1) {
      if (!validateUserForm(formData, setFormData)) {
        return false;
      }
      increaseStep();
    }

    if (formData.currentStep === 2) {
      const isCorporate = !!(
        formData.billing.title ||
        formData.billing.office ||
        formData.billing.token ||
        formData.billing.address
      );

      if (
        !formData.payOnDelivery &&
        !validatePaymentForm(formData, setFormData)
      ) {
        return false;
      }
      if (!formData.termsCond) {
        return setFormData({ ...formData, termsCondError: true });
      }
      setLoading(true);

      const pickup_time = moment(params.pickup_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const dropoff_time = moment(params.dropoff_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      const mobile = parsePhoneNumber(formData.user.mobile || "");

      const res = await rentVehicle({
        data: {
          pickup_time,
          dropoff_time,
          vehicle_id: match.params.vehicleId,
          pickup_region_id: params.pickup_place,
          dropoff_region_id: params.dropoff_place || params.pickup_place,
          user: {
            ...formData.user,
            mobile: `${mobile.countryCallingCode}${mobile.nationalNumber}`
          },
          payment_method: formData.payOnDelivery ? "pickup" : "credit_card",
          extras: formData.selectedExtras.map((extra) => ({
            id: extra.id,
            quantity: extra.quantity,
            price: extra.price
          })),
          is_corporate: isCorporate,
          ...(isCorporate && {
            billing: { ...formData.billing, is_corporate: isCorporate }
          }),
          ...(!formData.payOnDelivery && {
            callback_url: window.location.href,
            card: {
              holdername: formData.card.holdername,
              number: formData.card.number,
              cvc: formData.card.cvc,
              expire_year: formData.card.expireDate.substring(3, 5),
              expire_month: formData.card.expireDate.substring(0, 2)
            }
          })
        }
      });

      if (res.errors) {
        setFormData({
          ...formData,
          rentError: res.errors
        });
        setLoading(false);
        return false;
      }

      if (!formData.payOnDelivery) {
        localStorage.setItem(
          "checkoutData",
          JSON.stringify({
            extras: formData.selectedExtras,
            isCorporate,
            ...(isCorporate && {
              billing: { ...formData.billing, is_corporate: isCorporate }
            }),
            payment_method: "credit_card",
            user: formData.user,
            card: formData.card
          })
        );
        setTimeout(() => {
          document.querySelector(".paymentFormWrapper form").submit();
        }, 1000);
        setLoading(false);
        return setFormData({ ...formData, html3D: res.data.data["3d_html"] });
      }

      setPaymentResponse(res.data.data);
      setFormData({
        ...formData,
        stepStatuses: {
          ...formData.stepStatuses,
          [formData.currentStep]: "finish"
        }
      });
      window.scrollTo(0, 0);
      setLoading(false);
    }
  };

  return {
    getSegment,
    submitReservation,
    paymentResponse,
    extras,
    vehicle,
    loading,
    params,
    segments,
    formData,
    setFormData
  };
};

export default useReservation;

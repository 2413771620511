import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { useTranslate } from 'react-translate';
import { SingleDatePicker } from 'react-dates';
import { SearchInList } from '../../components';

import { getTimes } from './SearchForm.utils';
import TimeIcon from '../../../assets/time-icon.svg'

import styles from './SearchForm.module.scss';

const times = getTimes();

const ReturnDateAndTime = (props) => {
  const t = useTranslate('common');
  const {
    errors,
    receivingTime,
    receivingDate,
    focusedSingleDateInput,
    setFocusedSingleDateInput,
    handleReceivingTimeChange,
    receivingTimeVisible,
    setReceivingTimeVisible,
    handleReceivingDateChange,
  } = props;

  const mappedTimes = times.map((time) => {

    const currentTime = moment();
    const compareDate = moment(receivingDate);
    const controlTime = moment(time, "HH:mm");

    if (!moment(compareDate).isSame(currentTime, 'day') || currentTime <= controlTime) {
      return {
        id: time,
        name: time,
      }
  }else {
    return {}
  }

  }  
  );

  return (
    <div className={styles.selectDateAndTimeWrapper}>
      <div className={cx(styles.pickerWrapper, styles.field, styles.datePicker, {[styles.errorField]: errors.receivingDate })}>
        <span className={styles.label}>{t('alisTarihi')}</span>
        <SingleDatePicker
          date={receivingDate ? moment(receivingDate) : null}
          displayFormat="DD.MM.YYYY"
          firstDayOfWeek={1}
          placeholder={t('tarih')}
          hideKeyboardShortcutsPanel={true}
          onDateChange={handleReceivingDateChange}
          focused={focusedSingleDateInput}
          onFocusChange={({ focused }) => setFocusedSingleDateInput(focused)}
          id="your_unique_id"
          readOnly
          numberOfMonths={1}
          enableOutsideDays
          isOutsideRange={day => {
            let isBeforeEighteen = 0;
            const currentTime= moment();    // e.g. 11:00 pm
            const startTime = moment('00:00', "HH:mm");
            const endTime = moment('18:30', "HH:mm");
            const amIBetween = currentTime.isBetween(startTime , endTime);
            isBeforeEighteen = amIBetween ? 1 : 0;
            return day.isBefore(moment().subtract(isBeforeEighteen, 'd')) || day.isoWeekday() === 7;
          }}
        />
        {errors.receivingDate && (
          <div className={styles.error}>{t('gerekliAlan')}</div>
        )}
      </div>
      <div className={cx(styles.selectWrapper, styles.selectTimeWrapper, styles.field)}>
        <span className={styles.label}>{t('alisSaati')}</span>
        <div className={cx(styles.fakeInput, styles.time, {[styles.active]: !!receivingTime.name, [styles.errorField]: errors.receivingTime })}>
          <button onClick={() => setReceivingTimeVisible(true)}>
            <img src={TimeIcon} alt="" />
            <span>{receivingTime.name || t('saat')}</span>
          </button>
          {errors.receivingTime && (
            <div className={styles.error}>{t('gerekliAlan')}</div>
          )}
          { receivingTimeVisible && (
            <SearchInList
              searchVisible={false}
              type="time"
              list={mappedTimes}
              visible={receivingTimeVisible}
              setVisible={setReceivingTimeVisible}
              setData={handleReceivingTimeChange}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ReturnDateAndTime;
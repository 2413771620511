import React, { useState } from 'react';
import cx from 'classnames';
import { withRouter, Route } from 'react-router-dom';
import { Button, Helmet } from '../../common/components';
import { useTranslate } from 'react-translate';
import ShowRezervationItem from './ShowRezervationItem';

import styles from './ShowRezervation.module.scss';

import Banner from '../../assets/login-banner.jpg';

const ShowRezervation = (props) => {
  const t = useTranslate('showReservation');
  const tCm = useTranslate('common');

  const [value, setValue] = useState(props.match.params.reservarionCode || '');
  const [errors, setErrors] = useState({});

  const submit = async () => {
    if(!value) {
      return setErrors({ value: true });
    }
    props.history.push(`/show-reservation/${value}`);
    setErrors({ value: false });
  }

  return (
    <div className={styles.root}>
      <Helmet title={tCm('rezervasyon')} />
      <div className={styles.wrapper}>
        <h1>{t('rezervasyonGoruntule')}</h1>
        <div className={styles.banner} style={{ backgroundImage: `url(${Banner})`}} />
        <div className={styles.bannerInner}>
          <p>{t('bannerAciklama')}</p>
          <div className={styles.form}>
            <div className={cx(styles.fields, styles.codeWrapper)}>
              <h3>{t('rezervasyonNumarasi')}</h3>
              <div className={cx('formItem', {[styles.error]: errors.value })}>
                <input
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                  placeholder={t('rezNo')}
                />
                <div className={styles.errorText}>{t('gerekliAlan')}</div>
              </div>
            </div>
            <Button
              round
              type="primary"
              onClick={() => submit()}
            >
                {t('goruntule') }
            </Button>
          </div>
        </div>
        <Route path="/show-reservation/:reservarionCode" component={ShowRezervationItem} />
      </div>
    </div>
  )
}

export default withRouter(ShowRezervation);
